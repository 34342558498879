import React, { useEffect, useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';

import { setTitle } from 'Main/utils';
import { AuthContext } from 'api/context';
import CompanyProfile from './CompanyProfile';
import UserProfile from './UserProfile';

const title = 'Company & Staff Settings';

const CompanyAndUser = () => {
  const { user, newEndpoint } = useContext(AuthContext);
  const [company, setCompany] = useState(user.company);
  const companyAPI = newEndpoint('company');
  useEffect(() => setTitle(title), []);

  return (
    <div className="mx-auto p-2">
      <Row className="mt-2">
        <Col>
          <CompanyProfile company={company} setCompany={setCompany} API={companyAPI} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <UserProfile maxUsers={company.max_users} />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyAndUser;
