import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Button } from 'react-bootstrap';
import { People, Report, Gear, Headset, ChevronDoubleLeft, ChevronDoubleRight, Folder2, FileText } from 'react-bootstrap-icons';
import { AuthContext } from 'api/context';
import { NavLink } from 'react-router-dom';
import './styles/SidePanel.scss';
import { decode, encode, GlobalId } from '../id'
import { experimentalGroups } from '../feature-flags'

const SidePanel = ({ isAdmin }) => {
  const { company, auth } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    toggleCollapsed();
  }, []);

  const globalId = new GlobalId('Organization', 'legacy', company);
  // Elements for new Platform integration
  const globalCompanyId = encode(globalId);
  const session = auth.getToken();
  // End of new Platform integration

  const isExperial = experimentalGroups.includes(company);

  return (
    <div className="navPanel" data-nav-state={collapsed ? 'collapsed' : 'full'}>
      <Row className="logo">
        <img src="/logo.png" alt="logo" width="100%" />
      </Row>

      <div>
        <Row className="navItem">
          <NavLink exact activeClassName="font-weight-bold" to="/leads">
            <People size={24} className="mx-2" />
            <span>Leads</span>
          </NavLink>
        </Row>
        {/* <Row className="navItem">
          <NavLink exact activeClassName="font-weight-bold" to="/projects">
            <Folder2 size={24} className="mx-2" />
            <span>Projects</span>
          </NavLink>
        </Row> */}
        {/* <Row className="navItem">
          <NavLink exact activeClassName="font-weight-bold" to="/templates">
            <FileText size={24} className="mx-2" />
            <span>Templates</span>
          </NavLink>
        </Row> */}

        {/* <Row className="mb-3 ml-2">
          <Col>Reports</Col>
        </Row> */}
        {isAdmin && (
          <>
            <Row className="navItem">
              <button type="button" onClick={() => setOpen(!open)}>
                <Gear size={24} className="mx-2" />
                <span>Settings</span>
              </button>
              <div data-menu-state={open ? 'open' : 'close'} className="navSubMenu">
                <Row className="subMenuItem">
                  <NavLink exact activeClassName="font-weight-bold" to="/settings/company-profile">
                    Company & Staff
                  </NavLink>
                </Row>
                <Row className="subMenuItem">
                  <NavLink exact activeClassName="font-weight-bold" to="/settings/installation">
                    Installation
                  </NavLink>
                </Row>
                <Row className="subMenuItem">
                  <NavLink exact activeClassName="font-weight-bold" to="/settings/lead-management">
                    Lead Management
                  </NavLink>
                </Row>
                {/* <Row className="subMenuItem">
                  <NavLink exact activeClassName="font-weight-bold" to="/settings/custom">
                    Custom
                  </NavLink>
                </Row> */}

                {/* <Row className="subMenuItem">
                  <NavLink exact activeClassName="font-weight-bold" to="/settings/lead-generation">
                    Lead Generation
                  </NavLink>
                </Row> */}
                <Row className="subMenuItem">
                  <a target='_blank' exact activeClassName="font-weight-bold" href={`${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/auth/signin/legacy?session=${session}&organization=${globalCompanyId}&redirectTo=${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/settings/capture/configurations`}>
                    Lead Capture
                  </a>
                </Row>
                <Row className="subMenuItem">
                  <NavLink exact activeClassName="font-weight-bold" to="/settings/additional-fields">
                    CRM Customization
                  </NavLink>
                </Row>
                {/* {accessibilityList.includes(company) && <Row className="subMenuItem">
                  <NavLink exact activeClassName="font-weight-bold" to="/settings/customer-portal-setup">
                    <small><strong>Customer Portal Setup</strong></small>
                  </NavLink>
                </Row>} */}
                <Row className="subMenuItem">
                  <a target='_blank' exact activeClassName="font-weight-bold" href={`${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/auth/signin/legacy?session=${session}&organization=${globalCompanyId}&redirectTo=${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/settings/webhooks/endpoints`}>
                    Webhooks
                  </a>
                </Row>
                { isExperial && <Row className="subMenuItem">
                  <a target='_blank' exact activeClassName="font-weight-bold" href={`${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/auth/signin/legacy?session=${session}&organization=${globalCompanyId}&redirectTo=${process.env.REACT_APP_PLATFORM_PUBLIC_URL}/integrations`}>
                    Integrations
                  </a>
                </Row>
                }
              </div>
            </Row>
          </>
        )}

        <Row className="navItem">
          <NavLink exact activeClassName="font-weight-bold" to="/support">
            <Headset size={24} className="mx-2" />
            <span>Support</span>
          </NavLink>
        </Row>
      </div>

      <Button className="collapseToggle" onClick={toggleCollapsed}>
        <span>Collapse </span>
        {collapsed
          ? <ChevronDoubleRight size={24} />
          : <ChevronDoubleLeft size={24} />}
      </Button>
    </div>
  );
};

SidePanel.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default SidePanel;
