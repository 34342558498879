import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

function DesignLimitAlert({ designs, onClose, dismissible }) {
  if (!designs) {
    return null;
  }

  // danger
  if (designs?.remained <= 0) {
    return (
      <Alert variant="danger" className="p-2 mb-1" onClose={onClose} dismissible={dismissible}>
        You've used all of your Instant AI Designs. To purchase another package reach out to <a href="mailto:support@aerialytic.com">support@aerialytic.com</a>
      </Alert>
    );
  }
  // warning
  if (designs?.remained <= 10) {
    return (
      <Alert variant="warning" className="p-2 mb-1" onClose={onClose} dismissible={dismissible}>
        {`You have ${designs?.remained} Instant AI Designs left.`}
      </Alert>
    );
  }
  // warning
  if (designs?.remained <= 50) {
    return (
      <Alert variant="warning" className="p-2 mb-1" onClose={onClose} dismissible={dismissible}>
        You have {designs?.remained} AI solar designs left. Your billing cycle renews in {designs?.remained_days} days. To buy more, contact <a href="mailto:support@aerialytic.com">support@aerialytic.com</a>
      </Alert>
    );
  }
  return null;
}

DesignLimitAlert.defaultProps = {
  designs: null,
  onClose: undefined,
  dismissible: false,
};

DesignLimitAlert.propTypes = {
  designs: PropTypes.object,
  onClose: PropTypes.func,
  dismissible: PropTypes.bool,
};

export default DesignLimitAlert;
