import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button, ButtonGroup, InputGroup, Tabs, Tab, Nav } from 'react-bootstrap';
import { Trash, PencilFill } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';
import MDEditor from '@uiw/react-md-editor';

const FAQs = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('crm/portal_faqs/');
  const [key, setKey] = useState();
  const [MDEditorContent, setMDEditorContent] = React.useState();

  const [portalFAQs, setPortalFAQs] = useState([]);
  const [newPortalFAQ, setNewPortalFAQ] = useState();

  useEffect(() => {
    endpoint.list()
      .then((data) => {
        setPortalFAQs(data);
        if (data.length > 0) {
          setMDEditorContent(data[0]?.content);
          setKey('tab-0');
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(portalFAQs);
    updated[index][field] = value;
    setPortalFAQs(updated);
  };

  const handleUpdate = (faq) => {
    endpoint.update(faq.id, {...faq, content: MDEditorContent})
      .then(() => displaySnack({ variant: 'success', message: `Updated ${faq.name}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${faq.name}.` }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(portalFAQs);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setPortalFAQs(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.name}` }));
  };

  const initPortalFAQ = () => setNewPortalFAQ({ name: '', content: '' });
  const handleCreate = () => {
    // send POST request for new credit
    endpoint.create(newPortalFAQ)
      .then(() => endpoint.list().then((data) => setPortalFAQs(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newPortalFAQ.name}.` });
        setNewPortalFAQ();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new portal FAQ.',
      }));
  };

  const handleTabSelect = (targetKey, e) => {
    const prevIndex = key.split('-')[1];
    handleUpdateField(MDEditorContent, 'content', prevIndex);

    const index = targetKey.split('-')[1];
    setMDEditorContent(portalFAQs[index]?.content);
    setKey(targetKey);
  }

  return (
    <>
      {newPortalFAQ === undefined ? (
        <Row className="mt-1">
          <Col>
            <Button onClick={initPortalFAQ}>
              + FAQ Tab
            </Button>
          </Col>
        </Row>
      ) : (
          <ValidationForm onSave={handleCreate}>
            <Row className="font-weight-bold mt-2">
              <Col sm={5}>Tab Title</Col>
            </Row>
          <Row className="mt-3">
            <Col sm={5}>
              <Form.Control
                value={newPortalFAQ.name || ''}
                onChange={(e) => setNewPortalFAQ({ ...newPortalFAQ, name: e.target.value })}
                required
              />
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewPortalFAQ()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">+</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
      
      <Tab.Container id="faqs-tab-pills" defaultActiveKey="tab-0" onSelect={handleTabSelect}>
        <Row className='mt-3 p-2'>
          <Col sm={3}>
            <Nav variant='pills' className="flex-column">
              {portalFAQs.map((item, index) => (
                <Nav.Item>
                  <Nav.Link eventKey={`tab-${index}`}>{ item.name }</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {portalFAQs.map((item, index) => (
                <Tab.Pane eventKey={`tab-${index}`}>
                  <Row>
                    <Col>
                      <Form.Control
                        value={item.name || ''}
                        onChange={(e) => handleUpdateField(e.target.value, 'name', index)}
                        required
                      />
                    </Col>
                    <Col>
                      <div style={{ display: "block", float: "right" }}>
                        <ButtonGroup size="sm">
                          <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                            <Save />
                          </Button>
                          <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                            <Trash />
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col>
                      <MDEditor
                        height={500}
                        value={MDEditorContent || ""}
                        onChange={setMDEditorContent}
                      />
                    </Col>
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      
    </>
  );
};

export default FAQs;
