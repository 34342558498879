import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const SystemLosses = () => {
  const { company: id, user, newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/losses/');

  const [losses, setLosses] = useState([]);

  useEffect(() => {
    endpoint.retrieve(id)
      .then((data) => setLosses(data))
      .catch((e) => console.log(e));
    console.log(`system losses: ${JSON.stringify(losses)}`);
  }, []);

  const handleUpdateField = (value, field) => {
    const updated = losses;
    updated[field] = value;
    setLosses({...updated});
  };

  const handleUpdate = () => {
    endpoint.update(id, losses)
      .then(() => displaySnack({ variant: 'success', message: `Updated successfully.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update.` }));
  };

  return (
    <>
      <Row className='my-1'>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Soiling</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.soiling}
              onChange={(e) => handleUpdateField(e.target.value, 'soiling')}
              step="0.10"
              min="0.00"
              required
              disabled={user.role !== 'admin'}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <div class="p-1 mt-2 bg-dark text-white">* Shading analysis results are factored in calculations once the shading simulation is performed for each lead.</div>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Shading</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.shading}
              onChange={(e) => handleUpdateField(e.target.value, 'shading')}
              step="0.10"
              min="0.00"
              disabled
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <div class="p-1 mt-2 bg-dark text-white">* Reduction in the system's annual output due to snow covering is adjusted per state when leads are processed.</div>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Snow</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.snow}
              onChange={(e) => handleUpdateField(e.target.value, 'snow')}
              step="0.10"
              min="0.00"
              disabled
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Mismatch</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.mismatch}
              onChange={(e) => handleUpdateField(e.target.value, 'mismatch')}
              step="0.10"
              min="0.00"
              required
              disabled={user.role !== 'admin'}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Wiring</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.wiring}
              onChange={(e) => handleUpdateField(e.target.value, 'wiring')}
              step="0.10"
              min="0.00"
              required
              disabled={user.role !== 'admin'}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Connections</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.connections}
              onChange={(e) => handleUpdateField(e.target.value, 'connections')}
              step="0.10"
              min="0.00"
              required
              disabled={user.role !== 'admin'}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Light Induced Degradation</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.lid}
              onChange={(e) => handleUpdateField(e.target.value, 'lid')}
              step="0.10"
              min="0.00"
              required
              disabled={user.role !== 'admin'}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Nameplate Rating</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.nameplate_rating}
              onChange={(e) => handleUpdateField(e.target.value, 'nameplate_rating')}
              step="0.10"
              min="0.00"
              required
              disabled={user.role !== 'admin'}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Age</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.age}
              onChange={(e) => handleUpdateField(e.target.value, 'age')}
              step="0.10"
              min="0.00"
              required
              disabled={user.role !== 'admin'}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-1'>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Availability</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              value={losses.availability}
              onChange={(e) => handleUpdateField(e.target.value, 'availability')}
              step="0.10"
              min="0.00"
              required
              disabled={user.role !== 'admin'}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button type="submit" onClick={handleUpdate} disabled={user.role !== 'admin'}>Save</Button>
        </Col>
      </Row>
    </>
  );
};

export default SystemLosses;
