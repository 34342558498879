import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import LoginForm from './LoginForm';
import ResetForm from './ResetForm';
import RegisterForm from './RegisterForm';
import './Login.css';

const Login = ({ login }) => (
  <Container className="login-container" fluid>
    <Row className="h-100">
      {/* <Col sm={6} className="left-image" /> */}
      <Col md={6} className="offset-md-3">
        {/* <img id="logo" src="/logo.png" alt="logo" /> */}
        <Switch>
          <Route path="/password-reset/:token/:uid">
            <ResetForm />
          </Route>
          {/* <Route path="/signup/">
            <RegisterForm />
          </Route> */}
          <Route>
            <LoginForm exact login={login} />
          </Route>
        </Switch>
      </Col>
    </Row>
  </Container>
);

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Login;
