import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { stateOptions, countryOptions } from 'options';
import StatesCities from 'US-state-cities.json';
import ProvincesCities from 'CA-province-cities.json';
import { Typeahead } from 'react-bootstrap-typeahead';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const CreditRebate = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/credits_rebates/');
  const [country, setCountry] = useState('');
  // const [states, setStates] = useState([]);
  // const [state, setState] = useState();
  // const [cities, setCities] = useState([]);

  const [credits, setCredits] = useState([]);
  const [newCredit, setNewCredit] = useState();

  useEffect(() => {
    endpoint.list()
      .then((data) => setCredits(data))
      .catch((e) => console.log(e));
  }, []);

  // useEffect(() => {
  //   const stateList = stateOptions[country];
  //   setStates(stateList);
  // }, [country]);

  // useEffect(() => {
  //   const cityList = country === "Canada" ? ProvincesCities[state] : StatesCities[state];
  //   setCities(cityList);
  // }, [state]);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(credits);
    updated[index][field] = value;
    setCredits(updated);
  };

  const handleUpdate = (credit) => {
    endpoint.update(credit.id, credit)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${credit.name}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${credit.name}.` }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(credits);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setCredits(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.name}` }));
  };

  const initCredit = () => setNewCredit({ amount_type: 'F', cap_type: 'F', country: 'United States', state: null });
  const handleCreate = () => {
    // send POST request for new credit
    endpoint.create(newCredit)
      .then(() => endpoint.list().then((data) => setCredits(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newCredit.name}.` });
        setNewCredit();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new credit or rebate.',
      }));
  };

  return (
    <>
      <Row className="font-weight-bold mt-2">
        <Col sm={2}>Credit/Rebate</Col>
        <Col sm={2}>Country</Col>
        <Col sm={2}>State/Province</Col>
        <Col sm={2}>Amount</Col>
        <Col sm={2}>Cap. Amount</Col>
      </Row>

      {credits.map((item, index) => (
        <Row key={item.id} className="mt-2">
          <Col sm={2}>{item.name}</Col>
          <Col sm={2}>{item.country}</Col>
          <Col sm={2}>{item.state ? item.state : item.country === 'Canada' ? 'All Provinces' : 'All States'}</Col>
          <Col sm={2}>
            <InputGroup>
              <Form.Control
                className="w-75"
                type="number"
                value={item.amount || ''}
                onChange={(e) => handleUpdateField(e.target.value, 'amount', index)}
              />
              <Form.Control
                className="w-25 bg-light px-1"
                as="select"
                value={item.amount_type}
                onChange={(e) => handleUpdateField(e.target.value, 'amount_type', index)}
              >
                <option value="F">$</option>
                <option value="P">%</option>
                <option value="W">/W</option>
              </Form.Control>
            </InputGroup>
          </Col>
          <Col sm={2}>
            <InputGroup>
              <Form.Control
                className="w-75"
                type="number"
                value={item.cap || ''}
                onChange={(e) => handleUpdateField(e.target.value, 'cap', index)}
              />
              <Form.Control
                className="w-25 bg-light px-1"
                as="select"
                value={item.cap_type}
                onChange={(e) => handleUpdateField(e.target.value, 'cap_type', index)}
              >
                <option value="F">$</option>
                <option value="P">%</option>
                <option value="W">/W</option>
              </Form.Control>
            </InputGroup>
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {/* New Inverter */}
      {newCredit === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={initCredit}>
              + Credit/Rebate
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-3">
            <Col sm={2}>
              <Form.Control
                required
                value={newCredit.name || ''}
                onChange={(e) => setNewCredit({ ...newCredit, name: e.target.value })}
              />
            </Col>
            <Col sm={2}>
              <Form.Control
                required
                as="select"
                value={newCredit?.country || ''}
                onChange={(e) => {setNewCredit({ ...newCredit, country: e.target.value });}}
              >
                <option value="">Choose Country</option>
                {countryOptions.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Form.Control>
            </Col>
            <Col sm={2}>
              {/* <Form.Control
                as="select"
                value={newCredit.state || ''}
                onChange={(e) => setNewCredit({ ...newCredit, state: e.target.value })}
              >
                <option value="">{newCredit?.country === 'Canada' ? 'All Provinces' : 'All States'}</option>
                {stateOptions[newCredit?.country]?.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Form.Control> */}
              <Typeahead
                id="basic-typeahead-states"
                labelKey="name"
                onChange={(e) => setNewCredit({ ...newCredit, state: e[0] })}
                options={stateOptions[newCredit?.country]}
                placeholder={newCredit?.country === 'Canada' ? 'All Provinces' : 'All States'}
              />
            </Col>
            <Col sm={2}>
              <InputGroup>
                <Form.Control
                  className="w-75"
                  type="number"
                  value={newCredit.amount || ''}
                  onChange={(e) => setNewCredit({ ...newCredit, amount: e.target.value })}
                  required
                />
                <Form.Control
                  className="w-25 bg-light px-1"
                  as="select"
                  value={newCredit.amount_type}
                  onChange={(e) => setNewCredit({ ...newCredit, amount_type: e.target.value })}
                >
                  <option value="F">$</option>
                  <option value="P">%</option>
                  <option value="W">/W</option>
                </Form.Control>
              </InputGroup>
            </Col>
            <Col sm={2}>
              <InputGroup>
                <Form.Control
                  className="w-75"
                  type="number"
                  value={newCredit.cap || ''}
                  onChange={(e) => setNewCredit({ ...newCredit, cap: e.target.value })}
                />
                <Form.Control
                  className="w-25 bg-light px-1"
                  as="select"
                  value={newCredit.cap_type}
                  onChange={(e) => setNewCredit({ ...newCredit, cap_type: e.target.value })}
                >
                  <option value="F">$</option>
                  <option value="P">%</option>
                  <option value="W">/W</option>
                </Form.Control>
              </InputGroup>
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewCredit()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default CreditRebate;
