import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button, ButtonGroup, InputGroup, Tabs, Tab, Nav } from 'react-bootstrap';
import { Trash, PencilFill } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';
import MDEditor from '@uiw/react-md-editor';

const PortalWarranty = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('crm/portal_warranty/');
  const [MDEditorContent, setMDEditorContent] = React.useState();

  const [portalWarranty, setPortalWarranty] = useState();

  useEffect(() => {
    endpoint.list()
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          setPortalWarranty(data[0]);
          setMDEditorContent(data[0]?.content);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const handleUpdate = () => {
    endpoint.update(portalWarranty.id, { ...portalWarranty, content: MDEditorContent })
      .then(() => displaySnack({ variant: 'success', message: `Updated warranty.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed to update warranty.` }));
  };

  const handleDelete = () => {
    endpoint.remove(portalWarranty.id)
      .then(() => { setPortalWarranty(); displaySnack({ variant: 'success', message: `Deleted warranty.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete warranty.` }));
  };

  const initPortalWarranty = () => setNewPortalWarranty({ content: '' });
  const handleCreate = () => {
    // send POST request for new credit
    endpoint.create({ content: "" })
      .then(() => endpoint.list().then((data) => setPortalWarranty(data[0])))
      .then(() => {
        displaySnack({ variant: 'success', message: `Warranty added` });
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add warranty',
      }));
  };

  return (
    <>
      {portalWarranty === undefined ? (
        <Row className="mt-1">
          <Col>
            <Button onClick={handleCreate}>
              + Portal Warranty
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <div style={{ display: "block", float: "right" }}>
                <ButtonGroup size="sm">
                  <Button variant="outline-primary p-2" onClick={() => handleUpdate()}>
                    <Save />
                  </Button>
                  <Button variant="outline-primary p-2" onClick={() => handleDelete()}>
                    <Trash />
                  </Button>
                </ButtonGroup>
              </div>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <MDEditor
                height={500}
                value={MDEditorContent || ""}
                onChange={setMDEditorContent}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PortalWarranty;
