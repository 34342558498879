import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Button, Alert, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { client } from 'api/auth';

function LoginForm({ login }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [state, setState] = useState({ forgot: false });

  const toggleForgot = () => setState({ forgot: !state.forgot });

  const handleSubmit = (e) => {
    e.preventDefault();
    // submit form date, set error message if there was an issue
    if (state.forgot) {
      client.post('password/reset/', { email })
        .then(() => window.location.reload())
        .catch(() => setState({ ...state, error: 'User not found.' }));
    } else {
      client.post('token/', { email, password })
        .then((response) => login(response.data.key))
        .catch(() => setState({ ...state, error: 'Incorrect email/password combination.' }));
    }
  };

  return (
    <>
      <Alert
        variant="danger"
        onClick={() => setState({ ...state, error: null })}
        className="bad-login-alert"
        style={{ visibility: state.error ? 'visible' : 'hidden' }}
        dismissible
      >
        {state.error}
      </Alert>
      <div className="h-25" />

      <Form onSubmit={handleSubmit} className="login-form">
        <div className="title">
          {state.forgot ? 'Reset Password' : 'Login'}
        </div>
        <Form.Group controlId="Email">
          <Form.Label className="login-label">Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        {state.forgot ? null : (
          <Form.Group controlId="Password">
            <Form.Label className="login-label">Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        )}
        <Form.Row className="mt-3 mx-0">
          <Button
            variant="light"
            type="submit"
            className="font-weight-bold"
          >
            {state.forgot ? 'Reset' : 'Login'}
          </Button>
          <Button className="py-2 px-3 ml-1 text-white" variant="link" onClick={toggleForgot}>
            {state.forgot ? 'Cancel' : 'Forgot Password?'}
          </Button>
          {/* <hr />
          {!state.forgot && (
            <Link to="/signup">
              <Button
                variant="primary-600"
                className="font-weight-bold sign-up-btn mr-5"
              >
                Create new account
              </Button>
            </Link>
          )} */}
        </Form.Row>
      </Form>
    </>
  );
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

export default LoginForm;
