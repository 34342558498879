import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Extract trace targets from environment variable and convert comma-separated string to array
const traceTargets = process.env.REACT_APP_TRACE_TARGETS
  ? process.env.REACT_APP_TRACE_TARGETS.split(',')
  : [];

// Initialize Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: traceTargets,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
