import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Form, Accordion, ButtonGroup } from 'react-bootstrap';
import { AuthContext } from 'api/context';
import { setTitle } from 'Main/utils';

const NameValues = ({ title, url, eventKey }) => {
  const { newEndpoint } = useContext(AuthContext);
  const endpoint = newEndpoint(url);

  const [name, setName] = useState('');
  const [sources, setSources] = useState();
  const [sourceId, setsourceId] = useState();

  const getSources = () => {
    endpoint.list().then((res) => setSources(res));
  };

  useEffect(() => {
    getSources();
  }, []);

  const postSourceHandler = () => {
    endpoint.create({ name }).then(() => {
      setName('');
      getSources();
    });
  };

  const removeSourceHandler = (id) => {
    endpoint.remove(id).then(() => getSources());
  };

  const putSourceHandler = (id) => {
    endpoint.update(id, { name }).then(() => {
      setsourceId(-1);
      setName('');
      getSources();
    });
  };

  const editSourceHandler = (id) => {
    setsourceId(id);
    getSources();
  };

  const cancelSourceHandler = () => {
    setsourceId(-1);
    getSources();
  };

  const sourceRowButtons = (id) => {
    if (id !== sourceId) {
      return (
        <Col sm={6}>
          <ButtonGroup>
            <Button
              variant="outline-primary"
              onClick={() => removeSourceHandler(id)}
            >
              <i className="material-icons align-middle">delete</i>
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => editSourceHandler(id)}
            >
              <i className="material-icons align-middle">edit</i>
            </Button>
          </ButtonGroup>
        </Col>
      );
    }

    return (
      <Col sm={6}>
        <ButtonGroup>
          <Button
            variant="outline-primary"
            onClick={() => putSourceHandler(id)}
          >
            <i className="material-icons align-middle">save</i>
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => cancelSourceHandler(id)}
          >
            <i className="material-icons align-middle">cancel</i>
          </Button>
        </ButtonGroup>
      </Col>
    );
  };

  return (
    <Card className="mb-3 border shadow text-dark bg-white rounded">
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
          {title}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          <Row>
            <Col sm={8} className="h6 text-bold">
              {title}
            </Col>
          </Row>
          {sources ? sources.map((item) => (
            <Row key={item.id} className="mt-3">
              <Col sm={6}>
                {item.id !== sourceId
                  ? <Form.Control value={item.name} />
                  : <Form.Control onChange={(e) => setName(e.target.value)} />}
              </Col>
              { sourceRowButtons(item.id) }
            </Row>
          )) : null}
          <Row className="mt-3">
            <Col sm={6}>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={6}>
              <Button onClick={postSourceHandler}>{`+ ${title}`}</Button>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const CustomSettings = () => {
  const title = 'Custom Settings';
  useEffect(() => setTitle(title), []);

  return (
    <Row xs={1} lg={2}>
      <Accordion defaultActiveKey="0">
        <NameValues url="crm/sources/" title="Lead Sources" eventKey="1" />
        <NameValues url="crm/lead_status/" title="Lead Status" eventKey="2" />
        <NameValues url="crm/lead_reason_for_loss/" title="Reason for Loss" eventKey="3" />
      </Accordion>
    </Row>
  );
};

NameValues.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  eventKey: PropTypes.string.isRequired,
};

export default CustomSettings;
