import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setTitle } from 'Main/utils';

export default function Default() {
  const history = useHistory();

  useEffect(() => {
    const { pathname } = history.location;
    setTitle(pathname);
  }, [history]);

  // returns only if loaded in browser
  if (window.location === window.parent?.location) {
    return (
      <h1 className="m-3">
        Oops.
        <br />
        How did you get here?
      </h1>
    );
  }
}
