import React, { useContext, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

import { AuthContext } from 'api/context';
import Login from './Login';
import SidePanel from './SidePanel';
import Screen from './Screen';

document.headx = document.head || document.getElementsByTagName('head')[0];

function changeFavicon(src) {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) {
    document.headx.removeChild(oldLink);
  }
  document.headx.appendChild(link);
}

const IFrame = lazy(() => import('IFrame'));

// Main
const Main = () => {
  const { user, login, iframe } = useContext(AuthContext);

  useEffect(() => {
    if (process.env.REACT_APP_BACKEND === 'true') {
      document.title = 'Portal';
      changeFavicon('');
    }
  }, []);

  // returns only if loaded in browser
  if (window.location === window.parent.location) {
    return (
      <Route path="*">
        {user ? <MainContent user={user} iframe={iframe} /> : <Login login={login} />}
      </Route>
    );
  }
  return null;
};

const MainContent = ({ user, iframe }) => {
  useEffect(() => {
    const ignoreList = ["SUNCHE"]; // Ignore componies

    if (!iframe && !!user && !ignoreList.includes(user.company.id)) {
      loadZendeskScript();
    }

    return () => {
      if (window.zE) {
        window.zE('messenger', 'hide');
      }
    };
  }, [iframe, user]);

  return (
    <Container className="h-100">
      <Row className="h-100">
        <Screen />
        <SidePanel isAdmin={user.role === 'admin'} />
      </Row>
    </Container>
  );
}

function loadZendeskScript() {
  const zendesk = document.getElementById('ze-snippet');

  if (!zendesk) {
    const script = document.createElement("script");

    script.src = "https://static.zdassets.com/ekr/snippet.js?key=666e2207-7380-4d44-b60d-344b06ddaa1c";
    script.id = 'ze-snippet';
    script.async = true;

    document.body.appendChild(script);
  } else if (window.zE) {
    window.zE('messenger', 'show');
  }
}

export default Main;
