import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Telephone, EnvelopeOpen } from 'react-bootstrap-icons';

import { setTitle } from 'Main/utils';
import styles from './styles/support.module.css';

const ContactSupport = () => {
  const title = 'Customer Support';
  useEffect(() => setTitle(title));

  return (
    <div>
      <Row style={{ height: '70%', marginTop: '3%' }}>
        <Col className="d-flex justify-content-center">
          <div className={styles.supportBanner}>
            <h1> How can we help ?</h1>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: '50px' }}>
        <Col className="border-right">
          <Row>
            <Col className="d-flex justify-content-center">
              <Telephone size={25} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-center h4">Call Us</Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center h5">
              1-888-731-0571
            </Col>
          </Row>
          <Row>
            <Col className=" d-flex justify-content-center h6 text-center">
              Our Customer Success Team is available anytime between 9 am - 6 pm EST Monday to Friday.
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col className="d-flex justify-content-center">
              <EnvelopeOpen size={25} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-center h4">Email Us</Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center h5">
              support@aerialytic.com
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center h6 text-center">
              We aim to reply to all of our Customer Support emails within 12
              business hours from Monday to Friday.
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ContactSupport;
