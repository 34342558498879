import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import LoadProfileChart from './LoadProfileChart';

function LoadProfileChartModal({
  show,
  onHide,
  loadProfile,
  loadProfileIndex,
  handleUpdate,
  newLoadProfile = false,
  chartOnly = false,
}) {
  useEffect(() => {
    if (loadProfile) {
    }
  }, [loadProfile?.usages]);

  return (
    loadProfile !== null && (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-90w"
        // backdrop={false}
        // centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Load Profile Chart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadProfileChart
            loadProfile={loadProfile}
            loadProfileIndex={loadProfileIndex}
            handleUpdate={handleUpdate}
            newLoadProfile={newLoadProfile}
            chartOnly={chartOnly}
          />
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    )
  );
}

LoadProfileChartModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  usageData: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default LoadProfileChartModal;
