import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, InputGroup, Form, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const Inverters = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('inverters/');
  const invertersEndpoint = newEndpoint('hardware/inverters/');
  const manufacturersEndpoint = newEndpoint('hardware/manufacturers');

  const [inverters, setInverters] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [inverterModels, setInverterModels] = useState([]);
  const [newInverter, setNewInverter] = useState();
  const [spinner, setSpinner] = useState(false);
  const [inverterManufacturer, setInverterManufacturer] = useState();

  useEffect(() => {
    // get all available panel models, and company's configured panels
    // endpoint.list().then((data) => setModels(data));
    manufacturersEndpoint.list().then((data) => {
      const filteredData = data.filter((item) => item.product === 'INV' || item.product === 'PAN_INV').sort((a, b) => a.name.localeCompare(b.name));
      // const filteredData = data.sort((a, b) => a.name.localeCompare(b.name));
      setManufacturers(filteredData);
    });
    invertersEndpoint.list()
      .then((data) => setInverters(data))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    // get all panel models of manufacturer
    if (inverterManufacturer) {
      setSpinner(true);
      endpoint.list(`?manufacturer=${inverterManufacturer}`).then((data) => {
        setInverterModels(data.sort((a, b) => a.model.localeCompare(b.model)));
        setSpinner(false);
      });
    }
  }, [inverterManufacturer]);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(inverters);
    updated[index][field] = value;
    setInverters(updated);
  };

  const handleManufacturerChange = (e) => {
    const manufacturer = e[0]?.name;
    const { cost } = Object(newInverter);
    setInverterManufacturer(manufacturer);
    setNewInverter({ cost, manufacturer });
  };

  const handleModelChange = (e) => {
    // const inverter = inverterModels.find((item) => item.model === e.target.value);
    const inverter = e[0];
    const { manufacturer, cost } = newInverter;
    setNewInverter({ ...inverter, cost, manufacturer });
  };

  const handleUpdate = (inverter) => {
    invertersEndpoint.update(inverter.id, inverter)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${inverter.model}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${inverter.model}.` }));
  };

  const handleServiceability = (index) => {
    const updated = Array.from(inverters);
    updated[index].is_hidden = !updated[index].is_hidden;
    setInverters(updated);
  }

  const handleDelete = (index) => {
    const updated = Array.from(inverters);
    const rm = updated.splice(index, 1)[0];
    invertersEndpoint.remove(rm.id)
      .then(() => { setInverters(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.model}` }));
  };

  const handleCreate = () => {
    // send POST request for new inverter
    const { id, cost } = newInverter;
    invertersEndpoint.create({ inverter: id, cost })
      .then(() => invertersEndpoint.list().then((data) => setInverters(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newInverter.model}.` });
        setNewInverter();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new inverter.',
      }));
  };

  // const manufacturerSet = [
  //   ...new Set(models.map((inverter) => inverter.manufacturer.name)),
  // ];

  return (
    <>
      <Row className="font-weight-bold">
        <Col sm={2}>Inverter Manufacturer</Col>
        <Col sm={2}>Model</Col>
        <Col sm={1}>Type</Col>
        <Col sm={1}>Capacity</Col>
        <Col sm={1}>Efficiency</Col>
        <Col sm={1}>Cost (each)</Col>
        <Col sm={2}>Serviceable</Col>
      </Row>

      {inverters.map((item, index) => (
        <Row key={item.id} className="mt-2">
          <Col sm={2} className="mt-1">{item.manufacturer?.name}</Col>
          <Col sm={2} className="mt-1">{item.model}</Col>
          <Col sm={1} className="mt-1"><strong>{item.type === 'M' ? 'Micro' : 'Central'}</strong></Col>
          <Col sm={1} className="mt-1">
            {`${item.capacity}\xa0W`}
            {/* <InputGroup size="sm">
              <Form.Control
                type="number"
                value={item.capacity}
                onChange={(e) => handleUpdateField(e.target.value, 'capacity', index)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  {item.type === 'M' ? 'panels' : 'watts\xa0\xa0'}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup> */}
          </Col>

          <Col sm={1} className="mt-1">
            {`${item.efficiency}\xa0%`}
            {/* <InputGroup size="sm">
              <Form.Control
                type="number"
                value={item.efficiency}
                onChange={(e) => handleUpdateField(e.target.value, 'efficiency', index)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  %
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup> */}
          </Col>

          <Col sm={1}>
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                value={item.cost}
                onChange={(e) => handleUpdateField(e.target.value, 'cost', index)}
              />
            </InputGroup>
          </Col>
          <Col sm={2}>
            <Form.Check checked={!item?.is_hidden} onClick={() => handleServiceability(index)} />
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-1" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-1" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {/* New Inverter */}
      {newInverter === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={() => setNewInverter({ type: 'C' })}>
              + Add Inverters
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-3">
            <Col sm={2}>
              {/* <Form.Control
                required
                size="sm"
                value={newInverter.manufacturer || ''}
                onChange={(e) => setNewInverter({ ...newInverter, manufacturer: e.target.value })}
              /> */}
              {/* <Form.Control
                required
                size="sm"
                as="select"
                value={newInverter.manufacturer || ''}
                onChange={handleManufacturerChange}
              >
                <option disabled value="">Choose Manufacturer</option>
                {manufacturers.map((manf) => (
                  <option key={manf.name} value={manf.name}>{manf.name}</option>
                ))}
              </Form.Control> */}
              <Typeahead
                id="basic-typeahead-manufacturers"
                labelKey="name"
                onChange={handleManufacturerChange}
                options={manufacturers}
                placeholder="Choose Manufacturer"
                size='sm'
              />
            </Col>
            <Col sm={2}>
              {/* <Form.Control
                required
                size="sm"
                value={newInverter.model || ''}
                onChange={(e) => setNewInverter({ ...newInverter, model: e.target.value })}
              /> */}
              {spinner ? (
                <Spinner animation="border" />
              ) : (
                // <Form.Control
                //   required
                //   size="sm"
                //   as="select"
                //   onChange={handleModelChange}
                //   value={newInverter.model || ''}
                // >
                //   <option disabled value="">Choose Model</option>
                //   {newInverter?.manufacturer && inverterModels
                //     .filter(({ manufacturer }) => manufacturer?.name === newInverter.manufacturer)
                //     .map(({ id, model }) => (
                //       <option key={id} value={model}>{model}</option>
                //     ))}
                // </Form.Control>
                <Typeahead
                  id="basic-typeahead-inverters"
                  labelKey="model"
                  onChange={handleModelChange}
                  options={inverterModels
                    .filter(({ manufacturer }) => manufacturer?.name === newInverter?.manufacturer)}
                  placeholder="Choose Model"
                  size='sm'
                />
              )}
            </Col>
            {/* <Col sm={1}>
              <Form.Control
                as="select"
                value={newInverter.type || ''}
                onChange={(e) => setNewInverter({ ...newInverter, type: e.target.value })}
              >
                <option value="C">Central Inverter</option>
                <option value="M">Micro Inverter</option>
              </Form.Control>
            </Col> */}
            <Col sm={1} className="mt-1"><strong>{newInverter.type === 'M' ? 'Micro' : 'Central'}</strong></Col>
            <Col sm={1} className="mt-1">
              {newInverter.capacity ? `${newInverter.capacity}\xa0W` : '-'}
              {/* <InputGroup size="sm">
                <Form.Control
                  required
                  type="number"
                  onChange={(e) => setNewInverter({ ...newInverter, capacity: e.target.value })}
                  value={newInverter.capacity || ''}
                />
                <InputGroup.Append>
                  <InputGroup.Text>
                    {newInverter.type === 'M' ? 'panels' : 'watts'}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup> */}
            </Col>
            <Col sm={1} className="mt-1">
              {newInverter.efficiency ? `${newInverter.efficiency} %` : '-'}
              {/* <InputGroup size="sm">
                <Form.Control
                  type="number"
                  value={newInverter.efficiency}
                  onChange={(e) => setNewInverter({ ...newInverter, efficiency: e.target.value })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>
                    %
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup> */}
            </Col>
            <Col sm={1}>
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  value={newInverter.cost || ''}
                  onChange={(e) => setNewInverter({ ...newInverter, cost: e.target.value })}
                />
              </InputGroup>
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-1" size="sm" onClick={() => setNewInverter()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default Inverters;
