import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { Upload } from 'react-bootstrap-icons';
import styles from './ImportLead.module.css';

const FileUpload = ({ file, setFile, handleUpload }) => {
  const fileInput = useRef();
  const region = useRef();

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    region.current.style.background = '#ffffff';
  };

  const dropHandler = (e) => {
    e.preventDefault();
    fileInput.current.files = e.dataTransfer.files;
    dragLeaveHandler(e);
    setFile(e.dataTransfer.files[0]);
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
    region.current.style.background = '#f3f3f3';
  };

  return (
    <Card.Body>
      <div
        ref={region}
        className={styles.filezone}
        id="drop_zone"
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
      >
        {file !== undefined ? (
          <div className="text-left">
            <h5 className="font-weight-bold">{file.name}</h5>
            <p>{`Format: ${file.type}`}</p>
            <p>{`Size: ${file.size} bytes`}</p>
            <p>{`Last Modified: ${file.lastModifiedDate.toLocaleDateString()}`}</p>
            <Button onClick={handleUpload} variant="primary">Upload</Button>
            <Button onClick={() => setFile()} variant="primary" className="ml-1">Reset</Button>
          </div>
        ) : (
          <>
            <label htmlFor="csv">
              <input id="csv" type="file" onChange={changeHandler} ref={fileInput} hidden />
              <div className="p-2 btn-light border rounded">
                <Upload size={32} />
              </div>
            </label>
            <p className="mt-3">Drag and drop a CSV file here to import or select a file.</p>
          </>
        )}
      </div>
    </Card.Body>
  );
};

FileUpload.defaultProps = {
  file: undefined,
};

FileUpload.propTypes = {
  file: PropTypes.instanceOf(File),
  setFile: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
};

export default FileUpload;
