import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, InputGroup, Form, Button, ButtonGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { stateOptions, countryOptions } from 'options';
import StatesCities from 'US-state-cities.json';
import ProvincesCities from 'CA-province-cities.json';
import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';
import { Typeahead } from 'react-bootstrap-typeahead';

//
// Accordion Component for city Permit prices
const Permits = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/permit/');

  const [permits, setPermits] = useState([]);
  const [newPermit, setNewPermit] = useState();
  const [country, setCountry] = useState('United States');
  const [states, setStates] = useState([]);
  const [state, setState] = useState();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    endpoint.list().then((data) => setPermits(data));
  }, []);

  useEffect(() => {
    const stateList = stateOptions[country];
    setStates(stateList);
  }, [country]);

  useEffect(() => {
    const cityList = country === "Canada" ? ProvincesCities[state] : StatesCities[state];
    setCities(cityList);
  }, [state]);

  const handleUpdateCost = (value, index) => {
    const updated = Array.from(permits);
    updated[index].cost = value;
    setPermits(updated);
  };

  const handleUpdate = (permit) => {
    endpoint.update(permit.id, permit)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${permit.city} permit.` }))
      .catch(() => displaySnack({
        variant: 'danger',
        message: `Failed update ${permit.city} permit.`,
      }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(permits);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setPermits(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({
        variant: 'warning',
        message: `Failed to delete ${rm.city} permit.`,
      }));
  };

  const handleCreate = () => {
    // send POST request for new permit
    endpoint.create(newPermit)
      .then(() => endpoint.list().then((data) => setPermits(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newPermit.city} permit.` });
        setNewPermit();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new permit.',
      }));
  };

  return (
    <>
      <Row className="mb-2 mx-3 font-italic small">
        Permit costs will automatically be added to a proposal.
        If a lead is located in a city that you have not entered in this section, the associated permit cost will default to $0 and no cost will be added.
      </Row>
      {permits.length !== 0 && <Row className="font-weight-bold mt-2">
        {/* <Col sm={2}>Country</Col> */}
        <Col sm={4}>State/Province</Col>
        <Col sm={2}>City <small>(optional)</small></Col>
        <Col sm={2}>Cost</Col>
      </Row>}

      {permits.map((item, index) => (
        <Row key={item.id} className="mt-2">
          {/* <Col sm={2}>{item.country}</Col> */}
          <Col sm={4}>{item.state}</Col>
          <Col sm={2}>{item.city}</Col>
          <Col sm={2}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                value={item.cost}
                onChange={(e) => handleUpdateCost(e.target.value, index)}
              />
            </InputGroup>
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {/* New Permit */}
      {newPermit === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={() => setNewPermit({ cost: '0' })}>
              + Permit
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="font-weight-bold mt-2">
            <Col sm={2}>Country</Col>
            <Col sm={2}>State/Province</Col>
            <Col sm={2}>City</Col>
            <Col sm={2}>Cost</Col>
          </Row>
          <Row className="mt-2">
            <Col sm={2}>
              <Form.Control
                required
                as="select"
                value={newPermit.country || ''}
                onChange={(e) => {setNewPermit({ ...newPermit, country: e.target.value });setCountry(e.target.value);}}
              >
                <option disabled value="">Select Country</option>
                {countryOptions.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Form.Control>
            </Col>
            <Col sm={2}>
              {/* <Form.Control
                required
                as="select"
                value={newPermit.state || ''}
                onChange={(e) => {setNewPermit({ ...newPermit, state: e.target.value, city: '' });setState(e.target.value);}}
              >
                <option disabled value="">Select State</option>
                {states && states.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Form.Control> */}
              <Typeahead
                id="basic-typeahead-states"
                labelKey="name"
                onChange={(e) => {setNewPermit({ ...newPermit, state: e[0], city: '' });setState(e[0]);}}
                options={states}
                placeholder="Select State/Province"
              />
            </Col>
            <Col sm={2}>
              {/* <Form.Control
                // required
                as="select"
                value={newPermit.city || ''}
                onChange={(e) => setNewPermit({ ...newPermit, city: e.target.value })}
              >
                <option disabled value="">Select City</option>
                {cities && cities.map((item) => {
                  const city = item[0].toUpperCase() + item.slice(1).toLowerCase();
                  return <option key={city} value={city}>{city}</option>;
                })}
              </Form.Control> */}
              <Typeahead
                id="basic-typeahead-cities"
                labelKey="name"
                onChange={(e) => setNewPermit({ ...newPermit, city: e[0] })}
                options={
                  cities && cities.map((item) => {
                    const city = item[0].toUpperCase() + item.slice(1).toLowerCase();
                    return city;
                  })
                }
                placeholder="Select City"
              />
            </Col>
            <Col sm={2}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="number"
                  value={newPermit.cost || ''}
                  onChange={(e) => setNewPermit({ ...newPermit, cost: e.target.value })}
                />
              </InputGroup>
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewPermit()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default Permits;
