const { Resource } = require('@opentelemetry/resources');

const {
  SEMRESATTRS_SERVICE_NAME,
} = require('@opentelemetry/semantic-conventions');

const {
  WebTracerProvider,
  SimpleSpanProcessor,
  ConsoleSpanExporter,
  BatchSpanProcessor
} = require('@opentelemetry/sdk-trace-web');

const {
  OTLPTraceExporter,
} = require('@opentelemetry/exporter-trace-otlp-http');

const { registerInstrumentations } = require('@opentelemetry/instrumentation');

const {
  FetchInstrumentation,
} = require('@opentelemetry/instrumentation-fetch');
const {
  DocumentLoadInstrumentation,
} = require('@opentelemetry/instrumentation-document-load');

const consoleExporter = new ConsoleSpanExporter();

const collectorExporter = new OTLPTraceExporter({
  headers: {},
});

const provider = new WebTracerProvider({
  resource: new Resource({
    [SEMRESATTRS_SERVICE_NAME]: "Frontend Application",
  }),
});

const fetchInstrumentation = new FetchInstrumentation();
const documentLoadInstrumentation = new DocumentLoadInstrumentation();

fetchInstrumentation.setTracerProvider(provider);

if (process.env.NODE_ENV === 'development') {
  provider.addSpanProcessor(new SimpleSpanProcessor(consoleExporter));
} 
// else {
//   provider.addSpanProcessor(new BatchSpanProcessor(collectorExporter));
// }
provider.register();

registerInstrumentations({
  instrumentations: [fetchInstrumentation, documentLoadInstrumentation],
  tracerProvider: provider,
});

export default function TraceProvider({ children }) {
  return <>{children}</>;
}

export const tracer = provider.getTracer('frontend-app-tracer');
