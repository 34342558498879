import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { AuthContext } from 'api/context';
import Employees from './Employees';
import UserSetup from './UserSetup';

const UserProfile = ({ maxUsers }) => {
  const { newEndpoint } = useContext(AuthContext);
  const staffEndpoint = newEndpoint('staff');
  // const licenseEndpoint = newEndpoint('license');

  // const [state, setState] = useState({employees: [], licenses: []});
  const [staff, setStaff] = useState([]);
  const [setup, setSetup] = useState({ show: false });

  const getStaff = () => staffEndpoint.list().then((data) => setStaff(data));

  const getRowData = (e, data) => {
    console.log(data);
    setSetup({ id: data.id, show: true });
  };

  const handleAfterSaved = (event) => {
    getStaff();
    if (event == 'created') {
      setSetup({ show: false });
    }
  };

  /* const assigned_licenses = () => {
        return state.licenses.filter(license => license.assigned_to == null).length;
    }

    const mapping = (data) => {
        const license = data.license ? data.license : {name: "---", quota: "---"};
        return {
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            role: data.role,
            license: license.name,
            quota: license.quota
        }
    }

    async function fetchData() {
        let employees, licenses;
        await staffEndpoint.list()
            .then(data => employees = data.map(item => mapping(item)));
        await licenseEndpoint.list()
            .then(data => licenses = data);
        return {employees, licenses}
    } */

  useEffect(() => {
    getStaff();
    // fetchData().then(data => setState(data));
  }, []);

  return (
    <Card className="shadow mb-2 w-100">
      <Card.Body className="px-5 py-3">
        {/* <h6>Total Employees: {staff.length}</h6>
          <h6># of Total Licenses: {state.licenses.length}</h6>
          <h6># of Assigned Licenses: {assigned_licenses()}</h6> */}

        <Row className="my-2 justify-content-between">
          <Col>
            <h4 className="font-weight-bold float-left">Staff</h4>
          </Col>
          <Col>
            <Button className="float-right"
              onClick={() => setSetup({ show: !setup.show })}
            >
              {setup.show ? 'Staff' : '+ User'}
            </Button>
          </Col>
        </Row>

        {setup.show
          ? <UserSetup id={setup.id} endpoint={staffEndpoint} afterSaved={handleAfterSaved} />
          : (
            <Row>
              <Col>
                <h5 className="mb-3">{`Total Users: ${staff.length}/${maxUsers}`}</h5>
                <Employees data={staff} onRowClick={getRowData} />
              </Col>
            </Row>
          )}
      </Card.Body>
    </Card>
  );
};

export default UserProfile;
