import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { tableIcons, tableTheme, flatToolbar } from 'Main/tables';

const ActiveEmployees = ({ data, onRowClick }) => {
  const columns = [
    { field: 'first_name', title: 'First Name' },
    { field: 'last_name', title: 'Last Name' },
    { field: 'email', title: 'Email' },
    { field: 'role', title: 'Role' },
    { field: 'designs', title: 'AI Design Usage' },
  ];

  // TODO: show total employees / number of allowed employees
  return (
    <MuiThemeProvider theme={tableTheme}>
      <MaterialTable
        responsive
        data={data}
        columns={columns}
        icons={tableIcons}
        localization={{
          header: {
            actions: '',
          },
        }}
        title=""
        options={{
          headerStyle: { fontWeight: 'bold' },
          search: false,
          actionsColumnIndex: -1,
        }}
        components={{ Toolbar: flatToolbar }}
        onRowClick={onRowClick}
      />
    </MuiThemeProvider>
  );
};

ActiveEmployees.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default ActiveEmployees;
