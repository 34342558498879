import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';

const CSVSheet = ({
  grid,
  setGrid,
  updateCSV,
  backClick,
}) => {
  const [selected, setSelected] = useState({ start: { i: 0, j: 0 }, end: { i: 0, j: 0 } });
  const [{ from, to }, setReplace] = useState({ from: '', to: '' });

  const valueRenderer = (cell) => cell.value;

  const onCellsChanged = (changes) => {
    changes.forEach(({ row, col, value }) => {
      grid[row][col] = { ...grid[row][col], value };
    });
    setGrid([...grid]);
  };

  const changeSelect = (start, end) => {
    if (selected.start.i === start.i
      && selected.start.j === start.j
      && selected.end.i === end.i
      && selected.end.j === end.j) return;

    if (start.i === end.i && start.j === end.j && start.i === 0) {
      end.i = grid.length - 1;
    }
    setSelected({ start, end });

    if (start.i !== 0) {
      setReplace((old) => ({ ...old, from: grid[start.i][start.j].value }));
    }
  };

  const replaceSelected = () => {
    const { start, end } = selected;
    if (start.i === 0) start.i = 1;

    for (let { i } = start; i <= end.i; i++) {
      for (let { j } = start; j <= end.j; j++) {
        const { value } = grid[i][j];
        grid[i][j].value = String(value).replace(from, to);
      }
    }
    setGrid([...grid]);
  };

  return (
    <>
      <Card.Body>
        <p>Select cells that you want to edit.</p>
        <Row className="overflow-auto mr-1" style={{ maxHeight: '700px' }}>
          <Col>
            <ReactDataSheet
              data={grid}
              valueRenderer={valueRenderer}
              onCellsChanged={onCellsChanged}
              selected={selected}
              onSelect={({ start, end }) => changeSelect(start, end)}
              overflow="nowrap"
            />
          </Col>
        </Row>

        <p className="my-2">
          Find and replace the information on selected cells before importing these leads.
        </p>

        <Row>
          <Col>
            <Form.Control placeholder="Find" value={from} onChange={(e) => setReplace({ to, from: e.target.value })} />
          </Col>
          <Col>
            <Form.Control placeholder="Replace" value={to} onChange={(e) => setReplace({ from, to: e.target.value })} />
          </Col>
          <Col>
            <Button variant="primary" onClick={replaceSelected}>Replace All</Button>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button variant="outline-secondary" onClick={backClick}>Back</Button>
        <Button onClick={updateCSV}>Update CSV</Button>
      </Card.Footer>
    </>
  );
};

CSVSheet.propTypes = {
  grid: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string }), // TODO: add other keys?
    ),
  ).isRequired,
  setGrid: PropTypes.func.isRequired,
  updateCSV: PropTypes.func.isRequired,
  backClick: PropTypes.func.isRequired,
};

export default CSVSheet;
