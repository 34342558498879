import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { AuthContext } from 'api/context';
import ValidationForm from 'components/Form/Inputs';

const Warranty = () => {
  const { company: id, newEndpoint } = useContext(AuthContext);
  const API = newEndpoint('hardware/warranty');
  const [details, setDetails] = useState('');

  useEffect(() => {
    API.retrieve(id)
      .then((res) => setDetails(res.details))
      .catch(() => setDetails(''));
  }, []);

  const saveWarranty = () => (
    API.update(id, { details })
      .then((res) => setDetails(res.details))
  );

  return (
    <ValidationForm onSave={saveWarranty}>
      <Form.Control
        as="textarea"
        value={details}
        onChange={(e) => setDetails(e.target.value)}
        rows={5}
      />
      <Row className="my-3 float-left">
        <Col>
          <Button type="submit">
            Save
          </Button>
        </Col>
      </Row>
    </ValidationForm>
  );
};

export default Warranty;
