// Abstraction for API Endpoints
const REST = (path, request) => {
  path += path.endsWith('/') ? '' : '/'; // add trailing slash if missing

  const list = (query = '') => request('GET', path + query);

  const retrieve = (pk) => request('GET', `${path}${pk}/`);

  const remove = (pk) => request('DELETE', `${path}${pk}/`);

  const create = (data) => request('POST', path, data);

  const update = (pk, data, partial = false) => request(partial ? 'PATCH' : 'PUT', `${path}${pk}/`, data);

  const send = (method, data = null, url = '') => request(method, `${(path + url).replace(/\/$/, '')}/`, data);

  return {
    list,
    retrieve,
    remove,
    create,
    update,
    send,
  };
};

export default REST;
