import React, { Suspense, lazy, useContext } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { AuthContext } from 'api/context';
// import Leads from 'Leads';
// import Projects from 'Projects';
// import Templates from 'Templates';
import Settings from 'Settings';
import ContactSupport from 'Main/ContactSupport';
import Menu from './Menu';

import Default from './Default';
import './styles/Screen.scss';

// Test Routes
// import Test from 'components/Test.js';
// import ActiveEmployees from 'Settings/CompanyAndUser/UserProfile/Employees';
// import InactiveEmployees from 'Settings/CompanyAndUser/UserProfile/Employees/InactiveEmployees';
// import Report from 'Leads/Details/Report';
// import Proposal from 'Leads/Details/Proposals/';
// import PDFRender from 'Leads/Details/Report/PDFRender'
// import ProposalList from 'Leads/Details/Proposals/ProposalList';

const Leads = lazy(() => import('Leads'));
const Projects = lazy(() => import('Projects'));
const Templates = lazy(() => import('Templates'));

function Screen() {
  const history = useHistory();
  const { user, logout } = useContext(AuthContext);
  const doLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <div className="screen">
      <Row id="top" className="m-1 d-flex justify-content-between">
        <h1 id="screen-title">Welcome</h1>
        <Menu user={user} logout={doLogout} />
      </Row>

      <Row className="justify-content-md-start px-lg-5 mx-0">
        <Col className="p-0">
          <Suspense fallback={null}>
            <Switch>
              {/** Test Routes * /}
              <Route path="/Test" component={Test} />
              <Route path="/Proposal" component={Proposal} />
              <Route path="/ProposalList" component={ProposalList} />
              <Route path="/ActiveEmployees" component={ActiveEmployees} />
              <Route path="/InactiveEmployees" component={InactiveEmployees} />
              {/* <Route path="/leads" component={CustomerTable} /> */}

              {/** Real Routes */}
              <Route
                exact
                path="/"
                render={() => (user ? <Redirect to="/leads" /> : <Redirect to="/" />)}
              />
              <Route path="/leads/:uid?/:tab?" component={Leads} />
              <Route path="/projects" component={Projects} />
              <Route path="/templates/:doc?/:dir?" component={Templates} />
              <Route path="/settings">
                <Settings isAdmin={user.role === 'admin'} />
              </Route>
              <Route path="/support" component={ContactSupport} />
              <Route component={Default} />
            </Switch>
          </Suspense>
        </Col>
      </Row>
    </div>
  );
}

export default Screen;
