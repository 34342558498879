import React, { Suspense, lazy } from 'react';
import AuthProvider from 'api/context';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Main from 'Main';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './App.css';
import { tracer } from './tracing';

const IFrame = lazy(() => import('IFrame'));

const App = () => {
  const span = tracer.startSpan('Application');

  React.useEffect(() => {
    span.addEvent('ComponentDidMount');
    return () => {
      span.end();
    };
  }, []);
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/iframe/:uid?" component={IFrame} />
          <AuthProvider>
            <Main />
          </AuthProvider>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
