import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Spinner,
  Modal,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { Bar } from '@reactchartjs/react-chart.js';
import { object } from 'superstruct';
import { min } from 'mathjs';

function LoadProfileChart({
  loadProfile,
  loadProfileIndex,
  handleUpdate,
  newLoadProfile = false,
  chartOnly = false,
}) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const [monthlyData, setMonthlyData] = useState({
    Jan: '',
    Feb: '',
    Mar: '',
    Apr: '',
    May: '',
    Jun: '',
    Jul: '',
    Aug: '',
    Sep: '',
    Oct: '',
    Nov: '',
    Dec: '',
  });

  useEffect(() => {
    if (loadProfile) {
      loadProfile?.usages?.map((value, index) => {
        setMonthlyData({
          ...monthlyData,
          [months[index]]: value,
        });
      });
    }
  }, [loadProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMonthlyData({
      ...monthlyData,
      [name]: value,
    });
    // update usages on ligher level component

    console.log(`update handler type: ${newLoadProfile}`);
    if (!newLoadProfile) {
      handleUpdate(
        Object.values({
          ...monthlyData,
          [name]: value,
        }),
        'usages',
        loadProfileIndex
      );
    } else {
      handleUpdate({
        ...loadProfile,
        usages: Object.values({
          ...monthlyData,
          [name]: value,
        }),
      });
    }
  };

  const setAll = (e) => {
    const value = e.target.value;
    console.log(value);
    const newMonthlyData = { ...monthlyData };
    months.map((m, index) => {
      newMonthlyData[m] = value;
    });

    setMonthlyData(newMonthlyData);

    if (!newLoadProfile) {
      handleUpdate(Object.values(newMonthlyData), 'usages', loadProfileIndex);
    } else {
      handleUpdate({
        ...loadProfile,
        usages: Object.values(newMonthlyData),
      });
    }
  };

  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Usage',
        data:
          loadProfile?.usages ||
          Object.values(monthlyData).map((value) => parseFloat(value) || 0),
        backgroundColor: '#27282980',
        type: 'line',
        order: 1,
      },
    ],
  };

  const options = {
    legend: {
      display: true,
    },
    scales: {
      beginAtZero: true,
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Months',
          },
        },
      ],
      yAxes: [
        {
          ticks: { beginAtZero: true, suggestedMax: 2000 },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Usage [kWh/kW]',
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: (item) => item[0].label,
        label: (item) => `${item.yLabel.toFixed(2)} kWh/kW`,
      },
    },
  };
  return (
    <div>
      <Row>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          style={{ width: '1200px', maxWidth: '1200px' }}
        >
          <Bar redraw={true} data={data} options={options} />
        </Col>
      </Row>
      {!chartOnly && (
        <Row>
          <Col>
            <Row>
              <Col xs={12} sm={4}>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Set All
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="set-all-inputs"
                    aria-describedby="inputGroup-sizing-sm"
                    type="text"
                    name="all"
                    onChange={(e) => setAll(e)}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      kWh
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              {months.map((item, index) => (
                <Col xs={12} sm={4} md={2} lg={2}>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        {item}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label={index}
                      aria-describedby="inputGroup-sizing-sm"
                      type="text"
                      name={item}
                      value={loadProfile?.usages[index]}
                      onChange={(e) => handleChange(e)}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        kWh
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}

LoadProfileChart.propTypes = {
  usageData: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default LoadProfileChart;
