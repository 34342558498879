import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card } from 'react-bootstrap';
import { CheckCircle, ExclamationCircle } from 'react-bootstrap-icons';

const Result = ({ message, error, viewLeads }) => (
  <Card className="shadow w-50">
    <Card.Body>
      {message ? (
        <>
          <Alert variant="success">
            <h4>
              <CheckCircle className="mr-2" />
              {message}
            </h4>
            <h6>{error}</h6>
          </Alert>
        </>
      ) : (
        <Alert variant="danger">
          <h4>
            <ExclamationCircle className="mr-2" />
            {error}
          </h4>
        </Alert>
      )}
      <Button onClick={viewLeads}>
        View Leads
      </Button>
    </Card.Body>
  </Card>
);

Result.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  viewLeads: PropTypes.func.isRequired,
};

export default Result;
