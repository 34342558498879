import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, FormControl, Button, ButtonGroup, InputGroup, Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidatedForm from 'components/Form/Inputs';
import { FloatSelect, FloatInput } from 'components/Form/Inputs';

const TieredCost = ({ costs, handleUpdatePackage, index }) => {
  const { company: id, user, newEndpoint, displaySnack } = useContext(AuthContext);

  const [show, setShow] = useState();

  const [tieredCosts, setTieredCosts] = useState([]);
  const [defaultTier, setDefaultTier] = useState();
  const [newTieredCost, setNewTieredCost] = useState();

  useEffect(() => {
    if (costs) {
      setTieredCosts(costs);
      setDefaultTier(costs.filter((el) => el.name === 'Tier-default')[0] || undefined)
    }
  }, [costs]);

  const onSave = () => {
    handleUpdatePackage(tieredCosts, "tiered_costs", index)
  }

  const handleUpdateTieredCosts = (value, field, index, is_def = false) => {
    const updatedTieredCosts = [...tieredCosts];
    updatedTieredCosts[index][field] = value;
    setTieredCosts(updatedTieredCosts);
  }

  const appendDefaultTier = () => {
    const updatedTieredCosts = [...tieredCosts];
    let flag = false;
    updatedTieredCosts.forEach((el, i) => {
      if (el?.name === 'Tier-default') {
        updatedTieredCosts[i] = defaultTier;
        flag = true;
      }
    })
    if (flag === false) {
      console.log(flag)
      updatedTieredCosts.push(defaultTier);
    }
    setTieredCosts(updatedTieredCosts);
  }

  const handleDelete = (index) => {
    const updated = Array.from(tieredCosts);
    updated.splice(index, 1);
    setTieredCosts(updated);
  }

  const initNewTieredCost = () => setNewTieredCost({ idx: tieredCosts?.length === 0 ? 0 : tieredCosts?.length, name: '', cost: '', threshold: '' });

  return (
    <>
      <Row noGutters>
        <Col xs={10}>
          <FloatSelect
            label="Tiered Costs"
            nullValue={costs?.length === 0 ? "Add your first tired cost" : ""}
            options={costs?.sort((a, b) => a?.idx - b?.idx) ?? []}
            labelField="name"
            disabled={costs?.length === 0}
          />
        </Col>
        <Col xs={1} className='pr-1'>
          <Button onClick={() => setShow(true)} className='ml-1' style={{ width: "100%", height: "100%" }} variant="secondary">+</Button>
        </Col>
        <Col xs={1} className='pr-1'>
          <Button onClick={() => handleUpdatePackage([], "tiered_costs", index)} className='ml-1' style={{ width: "100%", height: "100%" }} variant="danger"><Trash></Trash></Button>
        </Col>
      </Row>

      <Modal
        size="lg"
        show={show}
        onHide={() => setShow()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Tiered Costs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tieredCosts.length !== 0 && tieredCosts.filter((el) => el?.name !== 'Tier-default').map((item, idx) => (
            <ValidatedForm onSave={() => { if (newTieredCost) setNewTieredCost(); }}>
              <Row className='mt-3' noGutters>
                <Col sm={5} xs={12} className="mt-1">
                  <FloatInput
                    required
                    size="sm"
                    label="Cost (per Watt)"
                    append="$"
                    value={item?.cost || ''}
                    onChange={(e) => handleUpdateTieredCosts(e.target.value, "cost", idx)}
                  />
                </Col>
                <Col sm={5} xs={12} className="mt-1 pl-1">
                  <FloatInput
                    required
                    size="sm"
                    label="System Size (kW) Threshold"
                    append="kW"
                    value={item?.threshold || ''}
                    onChange={(e) => handleUpdateTieredCosts(e.target.value, "threshold", idx)}
                  />
                </Col>
                <Col sm={2} xs={12} className="mt-1 pl-1">
                  <ButtonGroup size="sm">
                    <Button variant="outline-primary p-2" type="submit">
                      <Save />
                    </Button>
                    <Button variant="outline-primary p-2" onClick={() => handleDelete(idx)}>
                      <Trash />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ValidatedForm>
          ))}
          {newTieredCost === undefined ? (
            <Row className="mt-4">
              <Col>
                <Button size='sm' onClick={initNewTieredCost}>
                  + Tiered Cost
                </Button>
              </Col>
            </Row>
          ) : (
              <ValidatedForm onSave={() => { setTieredCosts([...tieredCosts, { ...newTieredCost, name: `Tier-${newTieredCost?.idx + 1}` }]); setNewTieredCost(); }}>
                <hr className='mt-4' />
                <Row className='mt-1'><Col><strong>New Tier</strong></Col></Row>
                <Row>
                  <Col sm={6} xs={12}>
                    <FloatInput
                      required
                      size="sm"
                      label="Cost (per Watt)"
                      append="$"
                      value={newTieredCost.cost || ''}
                      onChange={(e) => setNewTieredCost({ ...newTieredCost, cost: e.target.value })}
                    />
                  </Col>
                  <Col sm={6} xs={12} className="">
                    <FloatInput
                      required
                      size="sm"
                      label="System Size (kW) Threshold"
                      append="kW"
                      value={newTieredCost.threshold || ''}
                      onChange={(e) => setNewTieredCost({ ...newTieredCost, threshold: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 float-right">
                  <Col>
                    <ButtonGroup size="sm">
                      <Button variant="outline-primary p-2" type='submit'>
                        Save
                      </Button>
                      <Button variant="outline-primary p-2" onClick={() => setNewTieredCost()}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ValidatedForm>
          )}
          {tieredCosts.length > 0 && (
            <>
              <Row className='mt-4'><Col><strong>Default Tier</strong></Col></Row>
              <ValidatedForm onSave={() => { if (newTieredCost) setNewTieredCost(); appendDefaultTier(); }}>
                <Row className='mt-1' noGutters>
                    <Col sm={5} xs={12} className="mt-1">
                      <FloatInput
                        required
                        size="sm"
                        label="Cost (per Watt)"
                        append="$"
                        value={defaultTier?.cost || ''}
                        onChange={(e) => setDefaultTier({idx: -1, cost: e.target.value, name: 'Tier-default', threshold: null})}
                      />
                    </Col>
                    <Col sm={5} xs={12} className="mt-1 pl-1">
                      <FloatInput
                        required
                        disabled
                        size="sm"
                        label="System Size (kW) Threshold"
                        append="kW"
                      />
                    </Col>
                    <Col sm={2} xs={12} className="mt-1 pl-1">
                      <ButtonGroup size="sm">
                        <Button variant="outline-primary p-2" type="submit">
                          <Save />
                        </Button>
                        <Button variant="outline-primary p-2" onClick={() => setDefaultTier()}>
                          <Trash />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
              </ValidatedForm>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => setShow()}>
            Close
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              if (!defaultTier || tieredCosts.filter((el) => el.name === 'Tier-default').length === 0) return displaySnack({ variant: 'warning', message: "Default tier can not be empty"});
              const finalTieredCosts = tieredCosts.map((item, i) => {
                if (item.name === 'Tier-default') {
                  item.idx = tieredCosts.length;
                }
                return item;
              });
              handleUpdatePackage(finalTieredCosts, "tiered_costs", index);
              setShow(false);
            }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    
  );
};

export default TieredCost;
