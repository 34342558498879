import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap';

const OptIn = ({
  state,
  setState,
  finishMapping,
  backClick,
}) => {
  const [opt1, opt2, opt3] = state;
  const isOptedIn = (opt1 && opt2 && opt3.trim().length > 0);

  const updateState = (index, value) => {
    const current = Array.from(state);
    current[index] = value;
    setState(current);
  };

  return (
    <>
      <Card.Body className="px-5 py-3">
        <Row>
          <Col className="mt-2">These contacts are expecting to hear from my organization.</Col>
        </Row>
        <Row>
          <Col>
            <InputGroup>
              <Form.Check className="mr-3" type="radio" name="opt-in-1" id="opt-in-1-yes" label="Yes" checked={state[0]} onClick={() => updateState(0, true)} />
              <Form.Check type="radio" name="opt-in-1" id="opt-in-1-no" label="No" checked={!state[0]} onClick={() => updateState(0, false)} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            I have a prior relationship with these contacts and have emailed them at least once within the last 12 month.
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup>
              <Form.Check className="mr-3" type="radio" name="opt-in-2" id="opt-in-2-yes" label="Yes" checked={opt2} onClick={() => updateState(1, true)} />
              <Form.Check type="radio" name="opt-in-2" id="opt-in-2-no" label="No" checked={!opt2} onClick={() => updateState(1, false)} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            Initial below to confirm that your organization has permission to reach out to these contacts.
          </Col>
        </Row>
        <Row xs="6">
          <Col>
            {/*  className={opt3.trim().length === 0 && 'is-invalid'}  */}
            <Form.Control value={opt3} onChange={(e) => updateState(2, e.target.value)} />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button variant="outline-secondary" onClick={backClick}>Back</Button>
        <Button onClick={finishMapping} disabled={!isOptedIn}>
          Finish Import
        </Button>
      </Card.Footer>
    </>
  );
};

OptIn.propTypes = {
  state: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
  ).isRequired,
  setState: PropTypes.func.isRequired,
  backClick: PropTypes.func.isRequired,
  finishMapping: PropTypes.func.isRequired,
};

export default OptIn;
