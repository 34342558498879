import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { QuestionSquareFill } from 'react-bootstrap-icons';
import styled from 'styled-components';

import PermissionsCO from './PermissionsCO';
import PermissionsSA from './PermissionsSA';
import PermissionsPM from './PermissionsPM';

const StyledPopover = styled(Popover)`
  min-width: 600px;
  background-color: #f4f4f4;
  && .arrow::after {
    border-right-color: #f4f4f4;
  }
`;

// const iconStyle = { display: 'inline', color: '#22919E', marginLeft: '0.5rem' };

const popoverCO = (
  <StyledPopover className="popover-container" id="popover-basic-co">
    <Popover.Content>
      <PermissionsCO />
    </Popover.Content>
  </StyledPopover>
);

const popoverSA = (
  <StyledPopover className="popover-container" id="popover-basic-sa">
    <Popover.Content>
      <PermissionsSA />
    </Popover.Content>
  </StyledPopover>
);

const popoverPM = (
  <StyledPopover className="popover-container" id="popover-basic-pm">
    <Popover.Content>
      <PermissionsPM />
    </Popover.Content>
  </StyledPopover>
);

export const Admin = () => (
  <OverlayTrigger key="right" placement="right" overlay={popoverCO}>
    <sup>
      <QuestionSquareFill size={14} className="text-secondary ml-2 align-bottom" />
    </sup>
  </OverlayTrigger>
);

export const Manager = () => (
  <OverlayTrigger key="right" placement="right" overlay={popoverPM}>
    <sup>
      <QuestionSquareFill size={14} className="text-secondary ml-2 align-bottom" />
    </sup>
  </OverlayTrigger>
);

export const Sales = () => (
  <OverlayTrigger key="right" placement="right" overlay={popoverSA}>
    <sup>
      <QuestionSquareFill size={14} className="text-secondary ml-2 align-bottom" />
    </sup>
  </OverlayTrigger>
);
