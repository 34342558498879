import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import { AuthContext } from 'api/context';
import { setTitle } from 'Main/utils';
import leadFields from 'Settings/LeadFields';

import FileUpload from './FileUpload';
import FieldMapping from './FieldMapping';
import CSVSheet from './CSVSheet';
import OptIn from './OptIn';
import Result from './Result';

const title = 'Lead Management';

const ImportLeads = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const csvAPI = newEndpoint('crm/lead/csv/');
  const fieldsAPI = newEndpoint('crm/lead/fields/');

  const [viewIndex, setViewIndex] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [mapping, setMapping] = useState([]);
  const [CSV, setCSV] = useState();
  const [result, setResult] = useState();
  const [exportData, setExportData] = useState([]);

  const history = useHistory();
  const csvLink = useRef();
  // const [CSVFields, setCSVFields] = useState([]);

  // optIn-1 : Contacts are expecting to hear from Organization
  // optIn-2 : Organization have a prior relationship with contacts
  // optIn-3 : Organization has permission to reach out to contacts
  const [optIn, setOptIn] = useState([false, false, '']);
  const [fields, setFields] = useState(leadFields.fields);
  const [grid, setGrid] = useState([]);

  const exportCSV = () => {
    csvAPI.list().then((data) => {
      setExportData(data);
      csvLink.current.link.click();
    }).catch(() => displaySnack({ message: 'Unable to process request.', variant: 'danger' }));
  };

  // Nested Breadcrumb Component
  const Breadcrumbs = () => {
    if (viewIndex === 0) {
      return (
        <h5 className="font-weight-bold ml-3 my-3 mb-0">Import Leads</h5>
      );
    }

    const steps = ['Upload File', 'Map Fields', 'Review', 'Verify & Import'];
    return (
      <ol className="breadcrumb mb-0 rounded-bottom-0">
        {steps.slice(0, viewIndex).map((step, index) => {
          const key = `step${index + 1}`;
          return (
            <li key={key} className="breadcrumb-item">
              <Button className="p-0" variant="link" onClick={() => setViewIndex(index)}>
                <strong>{`Step ${index + 1}: `}</strong>
                {step}
              </Button>
            </li>
          );
        })}
        <li className="breadcrumb-item">
          <strong>{`Step ${viewIndex + 1}: `}</strong>
          {steps[viewIndex]}
        </li>
      </ol>
    );
  };

  const loadFieldAndGroups = () => {
    fieldsAPI.list().then((result) => {
      setFields([...fields, ...result.fields]);
    });
  };

  const csvLoad = (csv) => {
    const csvFields = Object.keys(csv[0]);
    setCSV(csv);

    const mappings = [];

    csvFields.forEach((csvField) => {
      const csvFieldName = csvField.replace(' ', '').toLowerCase();

      // Automatic Matching - Rule 1
      // try matching name in lowercase with trimmed spaces
      let matchedField = fields.find(({ name: field }) => {
        const fieldName = field.replace(' ', '').toLowerCase();
        return fieldName === csvFieldName;
      });

      // Automatic Matching - Rule 2
      // if no match is found, try a partial match of the trimmed, lowercase field
      if (matchedField === undefined) {
        matchedField = fields.find(({ name: field }) => {
          const fieldName = field.replace(' ', '').toLowerCase();
          return fieldName.includes(csvFieldName);
        });
      }

      // add field to mappings if it was matched by either rule
      if (matchedField) {
        mappings.push({ from: csvField, to: matchedField.name, preview: csv[0][csvField] });
      }
    });
    // update state from loaded csv
    setMapping(mappings);
    setViewIndex(1);
  };

  const csvUpload = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    csvAPI.send('PUT', formData)
      .then((res) => csvLoad(res))
      .catch((err) => console.log(err));
  };

  const newMapping = () => {
    setMapping((old) => [...old, { from: '', to: '', preview: '' }]);
  };

  const removeMapping = (index) => {
    mapping.splice(index, 1);
    setMapping([...mapping]);
  };

  const startMapping = () => {
    const gridData = [];

    const row = [];

    row.push({
      value: '', readOnly: true, width: '30px', className: 'px-2',
    });

    mapping.forEach((map) => {
      row.push({ value: map.to, readOnly: true, className: 'px-2' });
    });

    gridData.push(row);

    let rowIndex = 1;

    CSV.forEach((_row) => {
      const newrow = [];

      newrow.push({ value: rowIndex, readOnly: true, className: 'px-2' });
      rowIndex += 1;

      mapping.forEach((map) => {
        newrow.push({ value: _row[map.from], className: 'text-left px-1' });
      });

      gridData.push(newrow);
    });

    setGrid(gridData);

    setViewIndex((old) => old + 1);
  };

  const updateCSV = () => {
    for (let i = 1; i < grid.length; i += 1) {
      for (let j = 0; j < mapping.length; j += 1) {
        CSV[i - 1][mapping[j].from] = grid[i][j + 1].value;
      }
    }
    setViewIndex((old) => old + 1);
  };

  const finishMapping = () => {
    // const fieldname = optIn3.current.value.trim();
    // if (fieldname.length === 0) {
    //   optIn3.current.classList.add('is-invalid');
    //   optIn3.current.focus();
    //   return;
    // }
    // optIn3.current.classList.remove('is-invalid');

    const data = { csv: CSV, mapping, optIn };

    csvAPI.create(data)
      .then((_result) => {
        displaySnack({ message: _result.Message, variant: _result.Imported > 0 ? 'success' : 'danger' }, 20000);

        if (_result.Errors.length > 0) {
          for (let i = grid.length - 1; i > 0; i -= 1) {
            if (!_result.Errors.includes(i - 1)) {
              CSV.splice(i - 1, 1);
              grid.splice(i, 1);
            }
          }

          setCSV([...CSV]);
          setGrid([...grid]);
          setViewIndex(2);
        } else {
          setResult(_result);
        }
      })
      .catch(() => {
        displaySnack({ message: 'Unable to process request.', variant: 'danger' });
      });
  };

  const setFrom = (index, value) => {
    mapping[index].from = value;
    mapping[index].preview = CSV[0][value];
    setMapping([...mapping]);
  };

  const setTo = (index, value) => {
    mapping[index].to = value;
    setMapping([...mapping]);
  };

  const backClick = () => {
    setViewIndex((old) => old - 1);
  };

  useEffect(() => {
    setTitle(title);
    loadFieldAndGroups();
  }, []);

  const renderView = () => {
    switch (viewIndex) {
      case 0: // Upload document
        return (
          <FileUpload
            file={selectedFile}
            setFile={setSelectedFile}
            handleUpload={csvUpload}
          />
        );
      case 1: // Field Mapping
        return (
          <FieldMapping
            fields={fields}
            csvFields={Object.keys(CSV[0])}
            mapping={mapping}
            setFrom={setFrom}
            setTo={setTo}
            newMapping={newMapping}
            removeMapping={removeMapping}
            startMapping={startMapping}
            backClick={backClick}
          />
        );
      case 2: // Edit CSV Data
        return (
          <CSVSheet
            grid={grid}
            setGrid={setGrid}
            updateCSV={updateCSV}
            backClick={backClick}
          />
        );
      case 3: // Opt In
        return (
          <OptIn
            state={optIn}
            setState={setOptIn}
            finishMapping={finishMapping}
            backClick={backClick}
          />
        );
      default:
        return null;
    }
  };

  if (result) {
    return (
      <Result
        message={result.Message}
        error={result.Errors}
        viewLeads={() => history.push('/leads')}
      />
    );
  }

  return (
    <div className="mt-5 mx-0 w-100">
      <CSVLink
        filename="leads.csv"
        data={exportData}
        ref={csvLink}
      />
      {viewIndex === 0 && (
        <Card className="mb-5 shadow w-50" body>
          <h5 className="font-weight-bold">Export Leads</h5>
          <Button className="border" variant="light" onClick={exportCSV}>Download CSV</Button>
        </Card>
      )}
      <Card className={`shadow ${viewIndex === 0 && 'w-50'}`}>
        {!result && <Breadcrumbs />}
        {renderView()}
      </Card>
    </div>
  );
};

export default ImportLeads;
