import React from 'react';
import { Card } from 'react-bootstrap';

import Panels from './Panels';
import Inverters from './Inverters';
import MonitoringSystem from './MonitoringSystem';
import MountingHardware from './MountingHardware';

export default function Hardware() {
  return (
    <Card.Body>
      <Panels />
      <hr />
      <Inverters />
      <hr />
      <MonitoringSystem />
      <hr />
      <MountingHardware />
    </Card.Body>
  );
}
