import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

const FieldMapping = ({
  fields,
  csvFields,
  mapping,
  setFrom,
  setTo,
  newMapping,
  removeMapping,
  startMapping,
  backClick,
}) => {
  const selectedFromFields = csvFields
    .filter((f) => mapping.filter((mp) => mp.from === f).length > 0)
    .map((f) => <option>{f}</option>);

  const notSelectedfromFields = csvFields
    .filter((f) => mapping.filter((mp) => mp.from === f).length === 0)
    .map((f) => <option>{f}</option>);

  const groups = [...new Set(fields.map(({ group }) => group))];
  const toFields = groups.map((group) => (
    <optgroup key={group} label={group}>
      {fields
        .filter((field) => field.group === group)
        .map(({ id, name }) => <option key={id}>{name}</option>)}
    </optgroup>
  ));

  return (
    <>
      <Card.Body className="px-5 py-3">
        <Row sm={3}>
          <Col className="mt-2">Column Label from CSV</Col>
          <Col className="mt-2">Preview</Col>
          <Col className="mt-2">Aerialytic Label</Col>
        </Row>

        <hr />

        {mapping && mapping.map((m, index) => (
          <Row key={m.from} sm={3} className="mb-2">
            <Col>
              <Form.Control as="select" value={m.from || 'Choose...'} onChange={(e) => setFrom(index, e.target.value)}>
                <option>Choose...</option>
                <optgroup label="Not Selected">
                  {notSelectedfromFields}
                </optgroup>
                <optgroup label="Selected">
                  {selectedFromFields}
                </optgroup>
              </Form.Control>
            </Col>
            <Col>{m.preview}</Col>
            <Col>
              <InputGroup>
                <Form.Control as="select" value={m.to || 'Choose...'} onChange={(e) => setTo(index, e.target.value)}>
                  <option>Choose...</option>
                  {toFields}
                </Form.Control>
                <Button variant="link" onClick={() => removeMapping(index)}>
                  <Trash />
                </Button>
              </InputGroup>
            </Col>
          </Row>
        ))}
        <Row>
          <Col>
            <Button variant="primary" onClick={newMapping}>+ Field Mapping</Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            Unmatched columns will be ignored and will not be imported.
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button variant="outline-secondary" onClick={backClick}>Back</Button>
        <Button variant="primary" onClick={startMapping}>Start Import</Button>
      </Card.Footer>
    </>
  );
};

FieldMapping.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      group: PropTypes.string,
    }),
  ).isRequired,
  csvFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  mapping: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      preview: PropTypes.string,
    }),
  ).isRequired,
  setFrom: PropTypes.func.isRequired,
  setTo: PropTypes.func.isRequired,
  newMapping: PropTypes.func.isRequired,
  removeMapping: PropTypes.func.isRequired,
  startMapping: PropTypes.func.isRequired,
  backClick: PropTypes.func.isRequired,
};

export default FieldMapping;
