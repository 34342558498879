import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, InputGroup, Form, Button, ButtonGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const MonitoringSystem = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/monitoring/');

  const [monitoring, setMonitoring] = useState([]);
  const [newMonitoring, setNewMonitoring] = useState();

  // componentDidMount
  useEffect(() => {
    endpoint.list()
      .then((data) => setMonitoring(data))
      .catch((e) => console.log(e));
  }, []);

  const handleUpdateCost = (value, index) => {
    const updated = Array.from(monitoring);
    updated[index].cost = value;
    setMonitoring(updated);
  };

  const handleUpdate = (monitor) => {
    endpoint.update(monitor.id, monitor)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${monitor.model}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${monitor.model}.` }));
  };

  const handleServiceability = (index) => {
    const updated = Array.from(monitoring);
    updated[index].is_hidden = !updated[index].is_hidden;
    setMonitoring(updated);
  }

  const handleDelete = (index) => {
    const updated = Array.from(monitoring);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setMonitoring(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.model}` }));
  };

  const handleCreate = () => {
    // send POST request for new monitoring system
    endpoint.create(newMonitoring)
      .then(() => endpoint.list().then((data) => setMonitoring(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newMonitoring.model}.` });
        setNewMonitoring();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new monitoring system.',
      }));
  };

  return (
    <>
      <Row className="font-weight-bold mt-2">
        <Col sm={2}>Monitoring System</Col>
        <Col sm={2}>Cost</Col>
        <Col sm={2}>Serviceable</Col>
      </Row>

      {monitoring.map((item, index) => (
        <Row key={item.id} className="mt-2">
          <Col sm={2}>{item.model}</Col>
          <Col sm={2}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                value={item.cost}
                onChange={(e) => handleUpdateCost(e.target.value, index)}
              />
            </InputGroup>
          </Col>
          <Col sm={2}>
            <Form.Check checked={!item?.is_hidden} onClick={() => handleServiceability(index)} />
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {/* New Monitoring System */}
      {newMonitoring === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={() => setNewMonitoring({ flat_cost: true })}>
              + Add Monitoring System
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-3">
            <Col sm={2}>
              <Form.Control
                required
                value={newMonitoring.model || ''}
                onChange={(e) => setNewMonitoring({ ...newMonitoring, model: e.target.value })}
              />
            </Col>
            <Col sm={2}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  value={newMonitoring.cost || ''}
                  onChange={(e) => setNewMonitoring({ ...newMonitoring, cost: e.target.value })}
                />
              </InputGroup>
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewMonitoring()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}

    </>
  );
};

export default MonitoringSystem;
