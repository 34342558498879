import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useHistory } from 'react-router-dom';

// TODO: Remove Custom, and replace with AdditionalFields
import Custom from './Custom';
import ImportLead from './ImportLead';
import AdditionalFields from './AdditionalFields000';
import Installation from './Installation';
import CompanyAndStaff from './CompanyAndStaff';
import CustomerPortalSetup from './CustomerPortalSetup';

function Settings({ isAdmin }) {
  const history = useHistory();
  if (!isAdmin) {
    history.push('/');
    return null;
  }

  return (
    <Switch>
      <Route path="/settings/company-profile" component={CompanyAndStaff} />
      <Route path="/settings/installation" component={Installation} />
      <Route path="/settings/custom" component={Custom} />
      <Route path="/settings/lead-management" component={ImportLead} />
      <Route path="/settings/additional-fields" component={AdditionalFields} />
      <Route path="/settings/customer-portal-setup" component={CustomerPortalSetup} />
    </Switch>
  );
}

Settings.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default Settings;
