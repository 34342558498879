import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import styles from './float.module.css';
// import styles from './inputs.module.css';

const FloatInput = ({
  id,
  type,
  step,
  min,
  max,
  minLength,
  maxLength,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  append,
  prepend,
  isInvalid,
  invalidText,
  helpText,
  disabled,
  required,
  placeholder,
}) => {
  const labelClass = value ? styles.floatFilled : styles.floatLabel;
  const formControlId = id ?? generateId();
  // const Id = label.toLowerCase().replace(' ', '-')
  // const className = `${styles.select} form-control`

  // prepend = prepend ? <span>{prepend}</span> : null;
  // append = append ? <span>{append}</span> : null;

  return (
    <div className="position-relative">
      <InputGroup hasValidation={isInvalid}>
        {prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Form.Control
          id={formControlId}
          type={type}
          step={step}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          className={styles.floatField}
          isInvalid={isInvalid}
          disabled={disabled}
          required={required}
        />
        {append && (
          <InputGroup.Append>
            <InputGroup.Text>{append}</InputGroup.Text>
          </InputGroup.Append>
        )}
        {isInvalid && (
          <Form.Control.Feedback type="invalid">
            {invalidText || 'Invalid'}
          </Form.Control.Feedback>
        )}
      </InputGroup>
      <Form.Label
        className={labelClass}
        style={prepend && { marginLeft: '2.1rem' }}
      >
        {label}
      </Form.Label>
      {helpText && (
        <Form.Text id={`${formControlId}-help-block`} muted>{ helpText }</Form.Text>
      )}
    </div>
  );
};

function generateId(suffix) {
  return Math.floor(Math.random() * Date.now()).toString(16) + (suffix ? `-${suffix}` : '');
}

FloatInput.defaultProps = {
  type: 'text',
  label: 'Label',
  placeholder: null,
};

export default FloatInput;
