import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const RequestedDocuments = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('crm/portal_documents/');

  const [portalDocuments, setPortalDocuments] = useState([]);
  const [newPortalDocument, setNewPortalDocument] = useState();

  useEffect(() => {
    endpoint.list()
      .then((data) => setPortalDocuments(data))
      .catch((e) => console.log(e));
  }, []);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(portalDocuments);
    updated[index][field] = value;
    setPortalDocuments(updated);
  };

  const handleUpdate = (document) => {
    endpoint.update(document.id, document)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${document.name}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${document.name}.` }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(portalDocuments);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setPortalDocuments(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.title}` }));
  };

  const initPortalDocument = () => setNewPortalDocument({ name: '', required: false });
  const handleCreate = () => {
    // send POST request for new credit
    endpoint.create(newPortalDocument)
      .then(() => endpoint.list().then((data) => setPortalDocuments(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newPortalDocument.title}.` });
        setNewPortalDocument();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new portal step.',
      }));
  };

  return (
    <>
      <Row className="font-weight-bold mt-2">
        <Col sm={5}>File Name</Col>
        <Col sm={2}></Col>
      </Row>

      {portalDocuments.map((item, index) => (
        <Row key={item.id} className="mt-2">
          <Col sm={5}>
            <Form.Control
              value={item.name || ''}
              onChange={(e) => handleUpdateField(e.target.value, 'name', index)}
              required
            />
          </Col>
          <Col sm={2} className="my-auto">
            <Form.Check
              label="Required"
              type="checkbox"
              checked={item?.required}
              onChange={(e) => handleUpdateField(e.target.checked, 'required', index)}
            />
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {newPortalDocument === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={initPortalDocument}>
              + File
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-3">
            <Col sm={5}>
              <Form.Control
                value={newPortalDocument?.name || ''}
                onChange={(e) => setNewPortalDocument({ ...newPortalDocument, name: e.target.value })}
                required
              />
            </Col>
            <Col sm={2} className="my-auto">
              <Form.Check
                label="Required"
                type="checkbox"
                checked={newPortalDocument?.required}
                onChange={(e) => setNewPortalDocument({ ...newPortalDocument, required: e.target.checked })}
              />
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewPortalDocument()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default RequestedDocuments;
