export const setTitle = (title) => {
  const screenTitle = document.getElementById('screen-title');
  if (screenTitle && screenTitle.innerHTML !== title) {
    screenTitle.innerHTML = title;
    document.title = `${title} | Aerialytic`;
  }
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const fullCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatDate = (str) => new Date(str).toLocaleString('en-US');
//   const ops = { year: 'numeric', month: '2-digit', day: '2-digit' };
//   return new Date(str).toLocaleDateString(0, ops);
// };

export const formatCurrency = (amount, cents = false) => {
  if (cents) return fullCurrencyFormatter.format(amount);
  return currencyFormatter.format(amount);
};
