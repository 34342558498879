import { TypeRegistry, ParserRegistry, Encoder, Decoder, GlobalId, StringParser } from '@tryon-technology/global-id';

const typeRegistry = new TypeRegistry();
const parserRegistry = new ParserRegistry(typeRegistry);

typeRegistry.registerType('Organization', 'org');
parserRegistry.registerParser(
  'org',
  'legacy',
  new StringParser()
);

typeRegistry.registerType('Proposal', 'proposal');
parserRegistry.registerParser(
  'proposal',
  'legacy',
  new StringParser()
);

typeRegistry.registerType('Customer', 'cus');
parserRegistry.registerParser('cus', 'legacy', new StringParser());

const encoder = new Encoder(parserRegistry, typeRegistry);
const decoder = new Decoder(parserRegistry, typeRegistry);

const encode = (id) => {
  return encoder.encode(id);
};

const decode = (id) => {
  return decoder.decode(id);
};

export { encode, decode, GlobalId };
