import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';

import { AuthContext } from 'api/context';
import ValidationForm from 'components/Form/Inputs';
import { Form } from 'react-bootstrap';

const labels = [
  'General',
  'Eave',
  'Ridge',
  'Rake',
  'Hip',
  'Valley',
];

const Setbacks = () => {
  const { company: id, newEndpoint } = useContext(AuthContext);
  const API = newEndpoint('hardware/setbacks/');
  const [setbacks, setSetbacks] = useState({
    general: 36,
    eave: 0,
    ridge: 36,
    rake: 36,
    hip: 36,
    valley: 36,
  });

  useEffect(() => {
    API.retrieve(id)
      .then((res) => {
        setSetbacks(res);
      })
      .catch(() => {});
  }, []);

  const saveSetbacks = () => API.update(id, setbacks);

  return (
    <ValidationForm onSave={saveSetbacks}>
      <Row xs={1} sm={2} md={3}>
        {labels.map((label) => {
          const lbl = label.toLowerCase();
          return (
            <Col key={lbl} className="d-flex">
              <Form.Label className="mr-1" style={{ width: 75 }}>{label}</Form.Label>
              <InputGroup size="sm" className="mb-2">
                <FormControl
                  id={lbl}
                  type="number"
                  value={setbacks[lbl]}
                  defaultValue={setbacks[lbl]}
                  onChange={(e) => setSetbacks({ ...setbacks, [lbl]: +e.target.value })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>inches</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          );
        })}
      </Row>
      <Row className="my-3 float-left">
        <Col>
          <Button type="submit">
            Save
          </Button>
        </Col>
      </Row>
    </ValidationForm>
  );
};

export default Setbacks;
