import React, { useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import styles from './image.module.css';

const ImageCropper = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(10 / 3);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(props.image, croppedAreaPixels);
      props.onCrop(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, props]);

  return (
    <Modal show={props.show} onHide={props.onClose} className="mt-5 mx-auto">
      <Modal.Header closeButton>
        <Modal.Title>Position and size your image</Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.cropper}>
          <Cropper
            image={props.image}
            crop={crop}
            zoom={zoom}
            aspect={props.aspect || aspect || 4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className={styles.controls}>
          <Slider
            value={zoom}
            min={0.8}
            max={4.8}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={styles.controls}>
          <Slider
            value={aspect}
            min={0.8}
            max={4.8}
            step={0.1}
            aria-labelledby="Aspect"
            onChange={(e, aspect) => setAspect(aspect)}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={showCroppedImage}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageCropper;
