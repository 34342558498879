import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './styles/Menu.module.css';

const MenuItem = (props) => (
  <Row className={styles.pointer} onClick={props.onClick}>
    <Col>{props.children}</Col>
  </Row>
);

export default function Menu({ user, logout }) {
  const [opened, setOpened] = useState(false);
  const className = styles.menu + (!opened ? '' : ` ${styles.open}`);
  const fullName = `${user.first_name} ${user.last_name}`;

  const toggle = () => setOpened(!opened);
  const onKeyDown = (e) => {
    if (e.key === 'Escape' && opened) setOpened(false);
  };

  return (
    <div
      className={className}
      onClick={toggle}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex="0"
    >
      <img alt="upload" src={user.profile || '/profile.png'} />
      <div className="d-inline-block">
        <h4>{fullName}</h4>
        {user.company?.name}
      </div>
      {opened && (
        <>
          <hr className="my-1" />
          <MenuItem onClick={logout}>Logout</MenuItem>
        </>
      )}
    </div>
  );
}
