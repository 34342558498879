export const countryOptions = ['Canada', 'United States'];
export const stateOptions = {
  'United States': [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ],
  'Canada': [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'Saskatchewan',
    'Ontario',
    'Quebec',
    'New Brunswick',
    'Nova Scotia',
    'Newfoundland and Labrador',
    'Prince Edward Island',
    'Yukon',
    'Northwest Territories',
    'Nunavut',
  ],
};
export const statusOptions = [
  'Open',
  'Attempting',
  'Engaged',
  'Qualified',
  'Disqualified',
  'Presentation Set',
  'Presentation Flake',
  'Presentation Cancel',
  'Presentation Re-scheduled',
  'Presentation Completed',
  'Awaiting Customer Signature',
  'Awaiting Documents',
  'Closed Won',
  'Closed Lost',
  'Cancelled',
  'On Hold',
];
export const lossOptions = [
  'Not Interested',
  'Unable to Reach',
  'Low Usage',
  'Aesthetics',
  'Not Enough Value',
  'Awaiting New Tech',
  'Poor Customer Experience',
  'Bad Online Reviews',
  'Bad Timing',
  'Cancelled Presentation',
  'Bad Credit',
  'DQ - Shade',
  'DQ - Roof Age',
  'DQ- Roof Size',
  'DQ - Roof Type',
  'DQ - Failed Site Assessment',
  'DQ - Service Territory',
  'DQ - No Grid Capacity',
  'DQ - Renter',
  'DQ - Mobile Home',
  'DQ - Condo',
  'DQ - Selling Home',
  'DQ - Already Has Solar',
  'DQ - Utility Company',
  'DQ - No Phone',
  'DQ - Duplicate',
];
export const houseTypes = ['Detached', 'Semi-Detached', 'Townhouse'];

export const userRoles = ['Admin', 'Solar Advisor']; // , 'Project Manager']

export const convertStateToAbbr = (input, to) => {
  let states = [
      ['Alabama', 'AL'],
      ['Alaska', 'AK'],
      ['American Samoa', 'AS'],
      ['Arizona', 'AZ'],
      ['Arkansas', 'AR'],
      ['Armed Forces Americas', 'AA'],
      ['Armed Forces Europe', 'AE'],
      ['Armed Forces Pacific', 'AP'],
      ['California', 'CA'],
      ['Colorado', 'CO'],
      ['Connecticut', 'CT'],
      ['Delaware', 'DE'],
      ['District Of Columbia', 'DC'],
      ['Florida', 'FL'],
      ['Georgia', 'GA'],
      ['Guam', 'GU'],
      ['Hawaii', 'HI'],
      ['Idaho', 'ID'],
      ['Illinois', 'IL'],
      ['Indiana', 'IN'],
      ['Iowa', 'IA'],
      ['Kansas', 'KS'],
      ['Kentucky', 'KY'],
      ['Louisiana', 'LA'],
      ['Maine', 'ME'],
      ['Marshall Islands', 'MH'],
      ['Maryland', 'MD'],
      ['Massachusetts', 'MA'],
      ['Michigan', 'MI'],
      ['Minnesota', 'MN'],
      ['Mississippi', 'MS'],
      ['Missouri', 'MO'],
      ['Montana', 'MT'],
      ['Nebraska', 'NE'],
      ['Nevada', 'NV'],
      ['New Hampshire', 'NH'],
      ['New Jersey', 'NJ'],
      ['New Mexico', 'NM'],
      ['New York', 'NY'],
      ['North Carolina', 'NC'],
      ['North Dakota', 'ND'],
      ['Northern Mariana Islands', 'NP'],
      ['Ohio', 'OH'],
      ['Oklahoma', 'OK'],
      ['Oregon', 'OR'],
      ['Pennsylvania', 'PA'],
      ['Puerto Rico', 'PR'],
      ['Rhode Island', 'RI'],
      ['South Carolina', 'SC'],
      ['South Dakota', 'SD'],
      ['Tennessee', 'TN'],
      ['Texas', 'TX'],
      ['US Virgin Islands', 'VI'],
      ['Utah', 'UT'],
      ['Vermont', 'VT'],
      ['Virginia', 'VA'],
      ['Washington', 'WA'],
      ['West Virginia', 'WV'],
      ['Wisconsin', 'WI'],
      ['Wyoming', 'WY'],
  ];

  // So happy that Canada and the US have distinct abbreviations
  let provinces = [
      ['Alberta', 'AB'],
      ['British Columbia', 'BC'],
      ['Manitoba', 'MB'],
      ['New Brunswick', 'NB'],
      ['Newfoundland', 'NF'],
      ['Northwest Territory', 'NT'],
      ['Nova Scotia', 'NS'],
      ['Nunavut', 'NU'],
      ['Ontario', 'ON'],
      ['Prince Edward Island', 'PE'],
      ['Quebec', 'QC'],
      ['Saskatchewan', 'SK'],
      ['Yukon', 'YT'],
  ];

  let regions = states.concat(provinces);

  let i; // Reusable loop variable
  if (to == 'abbr') {
      input = input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
      for (i = 0; i < regions.length; i++) {
          if (regions[i][0] == input) {
              return (regions[i][1]);
          }
      }
  } else if (to == 'name') {
      input = input.toUpperCase();
      for (i = 0; i < regions.length; i++) {
          if (regions[i][1] == input) {
              return (regions[i][0]);
          }
      }
  }
}

export const getSnowLoss = (stateAbbr) => {
  let lossValues = [
    ['AL, AZ, AR, CA, FL, GA, HI, LA, MS, NV, NM, NC, OK, OR, SC, TN, TX, VA, WA', '0.0'],
    ['DE, KS, KY, MD, MO, NJ, UT, WV', '2.0'],
    ['CO, CT, ID, IL, IN, IA, ME, MA, NE, NH, OH, PA, RI', '4.0'],
    ['MI, MT, NY, ND, SD, VT, WI, WY', '7.0'],
    ['MN', '10.0'],
    ['AK', '16.0'],
  ];
  
  let i;
  for (i = 0; i < lossValues.length; i++) {
    let states = lossValues[i][0]
    if (states.includes(stateAbbr)) {
      return (lossValues[i][1]);
    }
  }
  return null;
}