import React, { useState } from 'react';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { CardImage } from 'react-bootstrap-icons';

import { countryOptions, stateOptions } from 'options';
import ValidatedForm, { FloatInput, FloatSelect } from 'components/Form/Inputs';
import ImageCropper from 'components/Form/Image';
import LicenseDetails from './LicenseDetails';

const CompanyProfile = ({ company, setCompany, API }) => {
  const [crop, setCrop] = useState({ show: false });
  const setAddress = (address) => setCompany({ ...company, address });

  const handleClose = () => setCrop({ show: false });

  const handleCrop = async (cropped) => {
    setCrop({ show: false, image: cropped });

    // prepare form data for submission
    const form = new FormData();
    const blob = await fetch(cropped).then((r) => r.blob());
    console.log(blob);
    form.append('logo', blob, 'logo.jpg');

    console.log('saving new logo');
    // Save New Logo
    API.update(company.id, form).then((data) => setCompany(data));
  };

  const handleLogo = (e) => {
    if (!e.target.files[0]) {
      setCrop({ show: false, image: undefined });
      return;
    }
    // set image from file
    setCrop({ show: true, image: URL.createObjectURL(e.target.files[0]) });
  };

  const saveCompany = async () => {
    const { logo, ...companyData } = company;
    return API.update(company.id, companyData).then((data) => setCompany(data));
  };

  return (
    <Card className="shadow mb-2 w-100">
      <Card.Body className="px-5 py-3">
        <h4 className="my-2 font-weight-bold">Company Profile</h4>
        <h5>{company.name}</h5>
        <Row>
          <Col sm={4} className="text-center">
            <ImageCropper
              show={crop.show}
              image={crop.image}
              onClose={handleClose}
              onCrop={handleCrop}
            />
            <div className="my-3">
              {!crop.show && company.logo ? (
                <img src={company.logo} height={90} alt="logo" />
              ) : (
                <CardImage size={90} color="var(--primary)" />
              )}
            </div>
            <Form.File
              id="comp-logo"
              className="d-none"
              onChange={handleLogo}
            />
            <Button
              size="sm"
              variant="outline-primary"
              className="w-100"
              onClick={() => document.getElementById('comp-logo').click()}
            >
              Upload
            </Button>
          </Col>

          <Col sm={8}>
            <ValidatedForm onSave={saveCompany}>
              <Row xs={1} sm={2} className="my-2">
                <Col>
                  <FloatInput
                    required
                    label="Street"
                    value={company.address.street}
                    onChange={(e) => setAddress({ ...company.address, street: e.target.value })}
                  />
                </Col>
                <Col>
                  <FloatInput
                    required
                    label="City"
                    value={company.address.city}
                    onChange={(e) => setAddress({ ...company.address, city: e.target.value })}
                  />
                </Col>
              </Row>
              <Row xs={1} sm={2} className="my-2">
                <Col>
                  <FloatSelect
                    required
                    options={company.address.country
                      ? stateOptions[company.address.country] : []}
                    label={company.address.country === 'United States' ? 'State' : 'Province'}
                    value={company.address.state}
                    onChange={(e) => setAddress({ ...company.address, state: e.target.value })}
                    disabled={!company.address.country}
                  />
                </Col>
                <Col>
                  <FloatSelect
                    required
                    options={countryOptions}
                    label="Country"
                    value={company.address.country}
                    onChange={(e) => setAddress({ ...company.address, country: e.target.value })}
                  />
                </Col>
              </Row>
              <Row xs={1} sm={2} className="my-2">
                <Col>
                  <FloatInput
                    required
                    label="Phone"
                    value={company.phone || ''}
                    onChange={(e) => setCompany({ ...company, phone: e.target.value })}
                  />
                </Col>
                <Col>
                  <FloatInput
                    required
                    label="Zipcode"
                    value={(company.address.zipcode || '').toUpperCase()}
                    onChange={(e) => setAddress({ ...company.address, zipcode: e.target.value })}
                  />
                </Col>
              </Row>
              <Row className="my-2 float-right">
                <Col>
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </ValidatedForm>
          </Col>
        </Row>

        <LicenseDetails company={company} />

        {/* <Row className="">
                <Col sm={2}>
                <Form.Label className="pt-2 h6 text-bold">
                    Brand Colour 1
                </Form.Label>
                </Col>
                <Col sm={2}>
                <Form.Label className="pt-2 h6 text-bold">
                    Brand Colour 2
                </Form.Label>
                </Col>
                <Col sm={2}>
                <Form.Label className="pt-2 h6 text-bold">
                    Brand Colour 3
                </Form.Label>
                </Col>

            </Row>

            <Row className="">
                <Col sm={2}><Form.Control placeholder="Hex#" /></Col>
                <Col sm={2}><Form.Control placeholder="Hex#" /></Col>
                <Col sm={2}><Form.Control placeholder="Hex#" /></Col>
            </Row> */}
      </Card.Body>
    </Card>
  );
};

export default CompanyProfile;
