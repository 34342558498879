import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, InputGroup, Button, ButtonGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const Roofs = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/roofs/');

  const [roofs, setRoofs] = useState([]);
  const [newRoof, setNewRoof] = useState();

  // componentDidMount
  useEffect(() => {
    endpoint.list()
      .then((data) => setRoofs(data))
      .catch((e) => console.log(e));
  }, []);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(roofs);
    updated[index][field] = value;
    setRoofs(updated);
  };

  const handleUpdate = (roof) => {
    endpoint.update(roof.id, roof)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${roof.name}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${roof.name}.` }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(roofs);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setRoofs(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.name}` }));
  };

  const handleCreate = () => {
    // send POST request for new roof
    endpoint.create(newRoof)
      .then(() => endpoint.list().then((data) => setRoofs(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newRoof.name}.` });
        setNewRoof();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new monitoring system.',
      }));
  };

  return (
    <>
      <Row className="font-weight-bold mt-2">
        <Col sm={3}>Roof Type</Col>
        <Col sm={2}>Cost</Col>
        <Col sm={3}>Cost Type</Col>
        <Col sm={2}>Serviceable</Col>
      </Row>

      {roofs.map((item, index) => (
        <Row key={item.id} className="mt-1">
          <Col sm={3}>{item.name}</Col>
          <Col sm={2}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                value={item.cost}
                onChange={(e) => handleUpdateField(e.target.value, 'cost', index)}
              />
            </InputGroup>
          </Col>
          <Col sm={3} className="my-auto">
            <Form.Check
              inline
              id={`flat_cost_${index}`}
              type="radio"
              label="Flat"
              checked={item?.flat_cost}
              onChange={() => handleUpdateField(true, 'flat_cost', index)}
            />
            <Form.Check
              inline
              id={`watt_cost_${index}`}
              type="radio"
              label="Per Watt"
              checked={!item?.flat_cost}
              onChange={() => handleUpdateField(false, 'flat_cost', index)}
            />
          </Col>
          <Col sm={2} className="my-auto">
            <Form.Check
              type="checkbox"
              checked={item.serviceable}
              onChange={(e) => handleUpdateField(e.target.checked, 'serviceable', index)}
            />
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {/* New Roof */}
      {newRoof === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={() => setNewRoof({ serviceable: true, flat_cost: true })}>
              + Roof
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-1">
            <Col sm={3}>
              <Form.Control
                required
                value={newRoof.name || ''}
                onChange={(e) => setNewRoof({ ...newRoof, name: e.target.value })}
              />
            </Col>
            <Col sm={2}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  value={newRoof.cost || ''}
                  onChange={(e) => setNewRoof({ ...newRoof, cost: e.target.value })}
                />
              </InputGroup>
              </Col>
              <Col sm={3} className="my-auto">
                <Form.Check
                  inline
                  id={`flat_cost_new`}
                  type="radio"
                  label="Flat"
                  checked={newRoof?.flat_cost}
                  onChange={() => setNewRoof({ ...newRoof, flat_cost: true })}
                />
                <Form.Check
                  inline
                  id={`watt_cost_new`}
                  type="radio"
                  label="Per Watt"
                  checked={!newRoof?.flat_cost}
                  onChange={() => setNewRoof({ ...newRoof, flat_cost: false })}
                />
              </Col>
            <Col sm={2} className="my-auto">
              <Form.Check
                type="checkbox"
                checked={newRoof.serviceable}
                onChange={(e) => setNewRoof({ ...newRoof, serviceable: e.target.checked })}
              />
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewRoof()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default Roofs;
