import React, { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Spinner,
  Modal,
} from 'react-bootstrap';
import { Trash, BarChart, Save2 } from 'react-bootstrap-icons';
import Save, { SaveOutline } from 'components/Save';

import { AuthContext } from 'api/context';
import ValidatedForm, { FloatInput, FloatSelect } from 'components/Form/Inputs';
import { stateOptions, countryOptions } from 'options';
import { convertStateToAbbr } from 'options';
import LoadProfileChart from './LoadProfileChart';
import LoadProfileChartModal from './LoadProfileChartModal';

const UtilityLoad = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const loadProfilesEndpoint = newEndpoint('hardware/load_profiles/');
  const loadProfileTypesEndpoint = newEndpoint('hardware/load_profiles/types/');

  const [modal, setModal] = useState();

  const [newLoadProfile, setNewLoadProfile] = useState();
  const [loadProfiles, setLoadProfiles] = useState([]);

  const [newLoadProfileType, setNewLoadProfileType] = useState();
  const [defaultLoadProfileType, setDefaultLoadProfileType] = useState();
  const [loadProfileTypes, setLoadProfileTypes] = useState([]);

  const [spinner, setSpinner] = useState(false);

  const [placeholderUsages, setPlaceholderUsages] = useState([
    400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400,
  ]);

  const countries = countryOptions.map((el) => ({ name: el }));
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (newLoadProfile && newLoadProfile?.country) {
      const stateList = stateOptions[newLoadProfile.country].map((el) => ({
        name: el,
      }));
      setStates(stateList);
    }
  }, [newLoadProfile?.country, newLoadProfile?.state]);

  useEffect(() => {
    loadProfileTypesEndpoint.list().then((data) => {
      if (data.length !== 0) {
        setLoadProfileTypes(data);
      }
    });
    loadProfilesEndpoint.list().then((data) => {
      if (data.length !== 0) {
        setLoadProfiles(data);
      }
    });
  }, []);

  const handleUpdateLoadProfileType = (value, field, index) => {
    const updated = Array.from(loadProfileTypes);
    updated[index][field] = value;
    setLoadProfileTypes(updated);
  };

  const handleUpdateLoadProfile = (value, field, index) => {
    const updated = Array.from(loadProfiles);
    updated[index][field] = value;
    if (updated[index]['name'].includes('default') && defaultLoadProfileType) {
      updated[index]['type']
    }
    setLoadProfiles(updated);
  };

  const postLoadProfileTypeHandler = (profileTypeToUpdate) => {
    const { id, ...totalLoadProfileType } = profileTypeToUpdate;

    if (id) {
      return loadProfileTypesEndpoint
        .update(id, { ...totalLoadProfileType })
        .then(() =>
          displaySnack({
            variant: 'success',
            message: `Updated ${profileTypeToUpdate.name}.`,
          })
        )
        .catch(() =>
          displaySnack({
            variant: 'danger',
            message: `Failed update ${profileTypeToUpdate.name}.`,
          })
        );
    }
    return loadProfileTypesEndpoint
      .create({ ...totalLoadProfileType })
      .then(() =>
        loadProfileTypesEndpoint
          .list()
          .then((data) => setLoadProfileTypes(data))
      )
      .then(() => {
        displaySnack({
          variant: 'success',
          message: `Added ${profileTypeToUpdate.name}.`,
        });
        setNewLoadProfileType();
      })
      .catch(() => {
        displaySnack({
          variant: 'danger',
          message: 'Failed to add new load profile type',
        });
      });
  };

  const postLoadProfileHandler = (profileToUpdate) => {
    const { id, ...totalLoadProfile } = profileToUpdate;

    if (id) {
      return loadProfilesEndpoint
        .update(id, { ...totalLoadProfile })
        .then(() =>
          displaySnack({
            variant: 'success',
            message: `Updated ${profileToUpdate.name}.`,
          })
        )
        .catch(() =>
          displaySnack({
            variant: 'danger',
            message: `Failed update ${profileToUpdate.name}.`,
          })
        );
    }
    return loadProfilesEndpoint
      .create({ ...totalLoadProfile })
      .then(() =>
        loadProfilesEndpoint.list().then((data) => setLoadProfiles(data))
      )
      .then(() => {
        displaySnack({
          variant: 'success',
          message: `Added ${profileToUpdate.name}.`,
        });
        setNewLoadProfile();
      })
      .catch(() => {
        displaySnack({
          variant: 'danger',
          message: 'Failed to add new load profile',
        });
      });
  };

  const handleDeleteType = (index) => {
    const updated = Array.from(loadProfileTypes);
    const rm = updated.splice(index, 1)[0];
    loadProfileTypesEndpoint
      .remove(rm.id)
      .then(() => {
        setLoadProfileTypes(updated);
        displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` });
      })
      .catch(() =>
        displaySnack({
          variant: 'warning',
          message: `Failed to delete ${rm.name}`,
        })
      );
  };

  const handleDelete = (index) => {
    const updated = Array.from(loadProfiles);
    const rm = updated.splice(index, 1)[0];
    loadProfilesEndpoint
      .remove(rm.id)
      .then(() => {
        setLoadProfiles(updated);
        displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` });
      })
      .catch(() =>
        displaySnack({
          variant: 'warning',
          message: `Failed to delete ${rm.name}`,
        })
      );
  };

  const initLoadProfileType = () => setNewLoadProfileType({ name: '' });

  const initLoadProfile = () =>
    setNewLoadProfile({
      name: '',
      country: 'United States',
      state: '',
      usages: [400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400],
      type: null,
    });
  
  const globalItem = (defaultLoadProfileType ? loadProfiles.find(
    (s) => s?.state == null && s?.name === `default-${loadProfileTypes.filter((el) => el.id === defaultLoadProfileType)[0]?.name}`
  ) : loadProfiles.find(
    (s) => s?.state == null && s?.name.includes('default')
  )) ?? {
    name: defaultLoadProfileType ? `default-${loadProfileTypes.filter((el) => el.id === defaultLoadProfileType)[0]?.name}` : 'default',
    country: null,
    state: null,
    usages: [400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400],
  };
  console.log(globalItem);
  console.log(loadProfiles);
  let globalIndex = loadProfiles.indexOf(globalItem);
  if (globalIndex < 0) {
    loadProfiles.push(globalItem);
    globalIndex = loadProfiles.length - 1;
  }
  return (
    <>
      <Tabs
        defaultActiveKey="all-states"
        id="uncontrolled-tab-example"
        className="mb-3 p-2 nav-pills nav-fill"
      >
        {/* default load profile */}
        <Tab eventKey="all-states" title="Default">
          <ValidatedForm onSave={() => postLoadProfileHandler(defaultLoadProfileType ? { ...globalItem, type: defaultLoadProfileType, name: `default-${loadProfileTypes.filter((el) => el.id === defaultLoadProfileType)[0]?.name}`} : globalItem)}>
            {/* <Row>
              <Col sm={4} xs={12}>
                <FloatInput
                  required
                  label="Name"
                  value="default"
                  onChange={(e) =>
                    handleUpdateLoadProfile(
                      e.target.value,
                      'name',
                      nostateIndex
                    )
                  }
                  disabled
                />
              </Col>
            </Row> */}
            <Row>   
              <Col>
                <LoadProfileChart
                  loadProfile={globalItem}
                  loadProfileIndex={globalIndex}
                  handleUpdate={handleUpdateLoadProfile}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatSelect
                  label="Type"
                  nullValue="Choose Load Profile Type"
                  allowNull
                  options={loadProfileTypes}
                  labelField="name"
                  value={defaultLoadProfileType || ''}
                  onChange={(e) =>
                    setDefaultLoadProfileType(
                      e.target.value
                    )
                  }
                />
              </Col>
              <Col>
                <Button variant='outline-primary px-2' size='lg' type="submit"><Save /></Button>
                <Button className='px-2 ml-1' variant='outline-primary' size='lg' onClick={() => handleDelete(globalIndex)}><Trash /></Button>
              </Col>
            </Row>
            <Row className="mt-3 float-left">
              <Col>
                <Button type="submit">Save</Button>
              </Col>
            </Row>
          </ValidatedForm>
          <br />
          <br />
          <br />
          <hr />

          {/* load profile type */}

          <strong>Load Profile Types:</strong>
          {loadProfileTypes.map((item, index) => (
            <ValidatedForm
              onSave={() => postLoadProfileTypeHandler(item)}
              className="mt-3 mb-1"
            >
              <Row>
                <Col sm={4} xs={12}>
                  <FloatInput
                    required
                    label="Name"
                    value={item?.name}
                    onChange={(e) =>
                      handleUpdateLoadProfileType(e.target.value, 'name', index)
                    }
                  />
                </Col>
                <Col>
                  <ButtonGroup size="sm">
                    <Button variant="outline-primary p-2" type="submit">
                      <Save />
                    </Button>
                    <Button
                      variant="outline-primary p-2"
                      onClick={() => handleDeleteType(index)}
                    >
                      <Trash />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ValidatedForm>
          ))}
          <br />
          {newLoadProfileType === undefined ? (
            <Row className="mt-3 mb-3 float-left">
              <Col>
                <Button onClick={initLoadProfileType}>
                  + Load Profile Type
                </Button>
              </Col>
            </Row>
          ) : (
            <ValidatedForm
              onSave={() => postLoadProfileTypeHandler(newLoadProfileType)}
              className="mb-4"
            >
              <Row>
                <Col sm={4} xs={12}>
                  <FloatInput
                    required
                    label="Name"
                    value={newLoadProfileType?.name}
                    onChange={(e) =>
                      setNewLoadProfileType({
                        ...newLoadProfileType,
                        name: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <ButtonGroup size="sm">
                    <Button variant="outline-primary p-2" type="submit">
                      <Save />
                    </Button>
                    <Button
                      variant="outline-primary p-2"
                      onClick={() => setNewLoadProfileType()}
                    >
                      <Trash />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ValidatedForm>
          )}
        </Tab>

        {/* state load profile */}
        <Tab eventKey="per-state" title="Per State/Province">
          {loadProfiles.filter((el) => !el.name.includes('default')).map(
            (item, index) =>
              item.state !== null &&
              item.name !== 'default' && (
                <Row>
                  <Col>
                    <ValidatedForm
                      key={item?.id}
                      onSave={() => postLoadProfileHandler(item)}
                    >
                      <Row>
                        <Col sm={3} xs={12}>
                          <FloatInput
                            required
                            label="Name"
                            value={item.name || ''}
                            onChange={(e) =>
                              handleUpdateLoadProfile(
                                e.target.value,
                                'name',
                                index
                              )
                            }
                          />
                        </Col>
                        <Col sm={3} xs={12}>
                          <FloatSelect
                            label="Country"
                            nullValue="Choose Country"
                            options={countries}
                            labelField="name"
                            value={item.country}
                            onChange={(e) =>
                              handleUpdateLoadProfile(
                                e.target.value,
                                'country',
                                index
                              )
                            }
                            required
                            disabled
                          />
                        </Col>
                        <Col sm={3} xs={12}>
                          <FloatSelect
                            required
                            label="State"
                            nullValue="Choose State"
                            options={stateOptions[item?.country].map((el) => ({
                              name: el,
                            }))}
                            labelField="name"
                            value={item.state || ''}
                            onChange={(e) =>
                              handleUpdateLoadProfile(
                                e.target.value,
                                'state',
                                index
                              )
                            }
                            disabled
                          />
                        </Col>
                        <Col sm={3} xs={12}>
                          <FloatSelect
                            required
                            label="Type"
                            nullValue="Choose Load Profile Type"
                            options={loadProfileTypes}
                            labelField="name"
                            value={item.type || ''}
                            onChange={(e) =>
                              handleUpdateLoadProfile(
                                e.target.value,
                                'type',
                                index
                              )
                            }
                          />
                        </Col>
                        <LoadProfileChartModal
                          loadProfile={item}
                          handleUpdate={handleUpdateLoadProfile}
                          loadProfileIndex={index}
                          show={modal === `${item?.name}`}
                          onHide={() => setModal(null)}
                        />
                      </Row>
                      <Row className="mt-3 mb-3 float-right">
                        <Col>
                          <ButtonGroup size="sm">
                            <Button variant="outline-primary p-2">
                              <BarChart
                                onClick={() => setModal(item?.name)}
                              />
                            </Button>
                            <Button variant="outline-primary p-2" type="submit">
                              <Save />
                            </Button>
                            <Button
                              variant="outline-primary p-2"
                              onClick={() => handleDelete(index)}
                            >
                              <Trash />
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </ValidatedForm>
                  </Col>
                </Row>
              )
          )}
          <br />
          {newLoadProfile === undefined ? (
            <Row className="mt-3">
              <Col>
                <Button onClick={initLoadProfile}>+ Load Profile</Button>
              </Col>
            </Row>
          ) : (
            <>
              <ValidatedForm
                onSave={() => postLoadProfileHandler(newLoadProfile)}
              >
                <Row>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Name"
                      value={newLoadProfile.name || ''}
                      onChange={(e) =>
                        setNewLoadProfile({
                          ...newLoadProfile,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      label="Country"
                      nullValue="Choose Country"
                      options={countries}
                      labelField="name"
                      value={newLoadProfile.country || ''}
                      onChange={(e) =>
                        setNewLoadProfile({
                          ...newLoadProfile,
                          country: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      required
                      label="State"
                      nullValue="Choose State"
                      options={states}
                      labelField="name"
                      value={newLoadProfile.state || ''}
                      onChange={(e) =>
                        setNewLoadProfile({
                          ...newLoadProfile,
                          state: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      required
                      label="Type"
                      nullValue="Choose Load Profile Type"
                      options={loadProfileTypes}
                      labelField="name"
                      value={newLoadProfile.type || ''}
                      onChange={(e) =>
                        setNewLoadProfile({
                          ...newLoadProfile,
                          type: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <LoadProfileChartModal
                    loadProfile={newLoadProfile}
                    handleUpdate={setNewLoadProfile}
                    newLoadProfile={true}
                    show={modal === 'new-load-profile'}
                    onHide={() => setModal(null)}
                  />
                </Row>
                <Row className="mt-3 mb-3 float-right">
                  <Col>
                    <ButtonGroup size="sm">
                      <Button variant="outline-primary p-2">
                        <BarChart
                          onClick={() => setModal('new-load-profile')}
                        />
                      </Button>
                      <Button variant="outline-primary p-2" type="submit">
                        <Save />
                      </Button>
                      <Button
                        variant="outline-primary p-2"
                        onClick={() => setNewLoadProfile()}
                      >
                        <Trash />
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ValidatedForm>
            </>
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default UtilityLoad;
