const leadFields = {
  groups: [
    { id: '1', name: 'Customer Details', fixed: 'true' },
    { id: '2', name: 'Property Details', fixed: 'true' },
    { id: '3', name: 'Utility Details', fixed: 'true' },
  ],
  fields: [
    { id: '1', name: 'First Name', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '2', name: 'Last Name', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '3', name: 'Phone', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '4', name: 'Phone 2', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '5', name: 'Email', type: 'Email', fixed: 'true', group: 'Customer Details' },
    { id: '6', name: 'Street', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '7', name: 'City', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '8', name: 'State', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '9', name: 'Country', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '10', name: 'Zipcode', type: 'Number', fixed: 'true', group: 'Customer Details' },
    { id: '11', name: 'UID', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '12', name: 'Referral UID', type: 'Text', fixed: 'true', group: 'Customer Details' },
    { id: '13', name: 'Lead Source', type: 'Dropdown', fixed: 'true', group: 'Customer Details', options_editable: 'true' },
    { id: '14', name: 'Lead Status', type: 'Dropdown', fixed: 'true', group: 'Customer Details', options_editable: 'true' },
    { id: '15', name: 'Reason for loss', type: 'Dropdown', fixed: 'true', group: 'Customer Details', options_editable: 'true' },
    { id: '16', name: 'Presentation Booked', type: 'Date and Time', fixed: 'true', group: 'Customer Details' },
    { id: '17', name: 'Presented', type: 'Date and Time', fixed: 'true', group: 'Customer Details' },
    { id: '18', name: 'Created', type: 'Date and Time', fixed: 'true', group: 'Customer Details' },
    { id: '19', name: 'New Task', type: 'Date and Time', fixed: 'true', group: 'Customer Details' },

    { id: '20', name: 'Street', type: 'Text', fixed: 'true', group: 'Property Details' },
    { id: '21', name: 'City', type: 'Text', fixed: 'true', group: 'Property Details' },
    { id: '22', name: 'State', type: 'Text', fixed: 'true', group: 'Property Details' },
    { id: '23', name: 'Country', type: 'Text', fixed: 'true', group: 'Property Details' },
    { id: '24', name: 'Zipcode', type: 'Text', fixed: 'true', group: 'Property Details' },
    { id: '25', name: 'Coordinates', type: 'Point', fixed: 'true', group: 'Property Details' },
    { id: '29', name: 'House Type', type: 'Text', fixed: 'true', group: 'Property Details' },
    { id: '30', name: 'Roof Type', type: 'Text', fixed: 'true', group: 'Property Details' },
    { id: '31', name: 'Roof Age', type: 'Number', fixed: 'true', group: 'Property Details' },

    { id: '32', name: 'Utility Provider', type: 'Text', fixed: 'true', group: 'Utility Details' },
    { id: '33', name: 'Average Monthly Bill', type: 'Number', fixed: 'true', group: 'Utility Details' },
    { id: '34', name: 'Connection Free', type: 'Number', fixed: 'true', group: 'Utility Details' },
    { id: '35', name: 'Rate', type: 'Number', fixed: 'true', group: 'Utility Details' },
  ],
};

export default leadFields;
