import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { Trash, PencilFill } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const SolarProcess = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('crm/portal_steps/');

  const [portalSteps, setPortalSteps] = useState([]);
  const [newPortalStep, setNewPortalStep] = useState();

  useEffect(() => {
    endpoint.list()
      .then((data) => setPortalSteps(data))
      .catch((e) => console.log(e));
  }, []);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(portalSteps);
    updated[index][field] = value;
    setPortalSteps(updated);
  };

  const handleUpdate = (step) => {
    endpoint.update(step.id, step)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${step.title}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${step.title}.` }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(portalSteps);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setPortalSteps(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.title}` }));
  };

  const initPortalStep = () => setNewPortalStep({ title: '', summary: '' });
  const handleCreate = () => {
    // send POST request for new credit
    endpoint.create(newPortalStep)
      .then(() => endpoint.list().then((data) => setPortalSteps(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newPortalStep.title}.` });
        setNewPortalStep();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new portal step.',
      }));
  };

  return (
    <>
      <Row className="font-weight-bold mt-2">
        <Col sm={3}>Title</Col>
        <Col sm={7}>Summary</Col>
      </Row>

      {portalSteps.map((item, index) => (
        <Row key={item.id} className="mt-2">
          <Col sm={3}>
            <Form.Control
              value={item.title || ''}
              onChange={(e) => handleUpdateField(e.target.value, 'title', index)}
              required
            />
          </Col>
          <Col sm={7}>
            <Form.Control
              value={item.summary || ''}
              onChange={(e) => handleUpdateField(e.target.value, 'summary', index)}
              required
            />
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {newPortalStep === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={initPortalStep}>
              + Step
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-3">
            <Col sm={3}>
              <Form.Control
                value={newPortalStep.title || ''}
                onChange={(e) => setNewPortalStep({ ...newPortalStep, title: e.target.value })}
                required
              />
            </Col>
            <Col sm={8}>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={newPortalStep.summary || ''}
                  onChange={(e) => setNewPortalStep({ ...newPortalStep, summary: e.target.value })}
                  required
                />
              </InputGroup>
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewPortalStep()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default SolarProcess;
