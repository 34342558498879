import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const timeout = 5000;

const ValidationForm = ({ children, onSave }) => {
  const [validated, setValidated] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      setTimeout(() => setValidated(false), timeout);
      return;
    }
    onSave();
  };

  return (
    <Form onSubmit={submitForm} noValidate validated={validated}>
      {children}
    </Form>
  );
};

export default ValidationForm;