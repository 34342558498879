import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const PermissionsSA = () => (
  <div>
    <Row>
      <Col className="h4 text-bold">User Permissions for Sales Advisor</Col>
    </Row>
    <Row className="w-100 mt-2">
      <Col>
        <Form.Check readOnly checked type="checkbox" label="Create Lead" />
      </Col>
      <Col>
        <Form.Check readOnly type="checkbox" label="Edit All Leads" />
      </Col>
      <Col>
        <Form.Check readOnly checked type="checkbox" label="Edit User Owned Leads" />
      </Col>
    </Row>

    <Row className="w-100">
      <Col>
        <Form.Check readOnly checked type="checkbox" label="Create Manual Design" />
      </Col>
      <Col>
        <Form.Check readOnly checked type="checkbox" label="Edit Manual Designs" />
      </Col>
      <Col>
        <Form.Check readOnly checked type="checkbox" label="Create Autonomous Design" />
      </Col>
    </Row>

    <Row className="w-100">
      <Col>
        <Form.Check readOnly checked type="checkbox" label="Edit Autonomous Design" />
      </Col>
      <Col>
        <Form.Check readOnly checked type="checkbox" label="Create Proposal" />
      </Col>
      <Col>
        <Form.Check readOnly checked type="checkbox" label="Edit Proposal" />
      </Col>
    </Row>

    <Row className="w-100">
      <Col>
        <Form.Check readOnly type="checkbox" label="Edit & View Company Settings" />
      </Col>
      <Col>
        <Form.Check readOnly type="checkbox" label="Delete Leads" />
      </Col>
      <Col>
        <Form.Check readOnly type="checkbox" label="Reassign Leads" />
      </Col>
    </Row>

    <Row className="w-100">
      <Col>
        <Form.Check readOnly type="checkbox" label="Upload Lead List" />
      </Col>
      <Col>
        <Form.Check readOnly type="checkbox" label="Download Lead List" />
      </Col>
      <Col>
        <Form.Check readOnly type="checkbox" label="View & Edit Installation Settings" />
      </Col>
    </Row>

    <Row className="w-100">
      <Col>
        <Form.Check readOnly type="checkbox" label="View All Sales Reports" />
      </Col>
      <Col>
        <Form.Check readOnly checked type="checkbox" label="View Personal Slaes Report" />
      </Col>
      <Col />
    </Row>
  </div>
);

export default PermissionsSA;
