import { AuthContext } from 'api/context';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { Row, Col, Card, Button, Form, Accordion, ButtonGroup, InputGroup, Modal } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, Trash, PencilSquare } from 'react-bootstrap-icons';
import { setTitle } from 'Main/utils';
import SolarProcess from './SolarProcess';
import RequestedDocuments from './RequestedDocuments';
import FAQs from './FAQs';
import PortalWarranty from './Warranty';


const CustomerPortalSetup = () => {
    const { newEndpoint } = useContext(AuthContext);
    const [key, setKey] = useState(null);
    setTitle('Customer Portal setup');

    return (
        <div>
            <Accordion className="my-5" onSelect={(active) => setKey(active)}>
                <Card className="mb-3 border shadow text-dark bg-white rounded">
                    <Accordion.Toggle
                        className="bg-white border-0"
                        as={Card.Header}
                        eventKey="0"
                    >
                        <Row className="h5 font-weight-bold text-primary mb-0">
                        <Col>Solar Process Stages</Col>
                        <Col className="text-right h6">
                            <CaretDownFill />
                        </Col>
                        </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {key === '0' && <SolarProcess />}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card className="mb-3 border shadow text-dark bg-white rounded">
                    <Accordion.Toggle
                        className="bg-white border-0"
                        as={Card.Header}
                        eventKey="1"
                    >
                        <Row className="h5 font-weight-bold text-primary mb-0">
                        <Col>Requested Documents</Col>
                        <Col className="text-right h6">
                            <CaretDownFill />
                        </Col>
                        </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                        {key === '1' && <RequestedDocuments />}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card className="mb-3 border shadow text-dark bg-white rounded">
                    <Accordion.Toggle
                        className="bg-white border-0"
                        as={Card.Header}
                        eventKey="2"
                    >
                        <Row className="h5 font-weight-bold text-primary mb-0">
                        <Col>FAQs</Col>
                        <Col className="text-right h6">
                            <CaretDownFill />
                        </Col>
                        </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                        {key === '2' && <FAQs />}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className="mb-3 border shadow text-dark bg-white rounded">
                    <Accordion.Toggle
                        className="bg-white border-0"
                        as={Card.Header}
                        eventKey="3"
                    >
                        <Row className="h5 font-weight-bold text-primary mb-0">
                        <Col>Warranty</Col>
                        <Col className="text-right h6">
                            <CaretDownFill />
                        </Col>
                        </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                        {key === '3' && <PortalWarranty />}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
};

export default CustomerPortalSetup;