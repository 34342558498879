import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import styles from './float.module.css';

const FloatSelect = ({
  options,
  valueField,
  labelField,
  label,
  value,
  onChange,
  onBlur,
  nullValue,
  allowNull,
  isInvalid,
  invalidText,
  disabled,
  required,
  size,
}) => {
  const labelClass = value ? styles.floatFilled : styles.floatLabel;
  // const Id = label.toLowerCase().replace(' ', '-')
  // const className = `form-control ${styles.floatField}`

  return (
    <div className="position-relative">
      <InputGroup hasValidation={isInvalid}>
        <Form.Control
          as="select"
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          className={size === 'sm' ? styles.floatFieldSmall : styles.floatField}
          isInvalid={isInvalid}
          disabled={disabled}
          required={required}
          size={size}
        >
          <option value="" disabled={!allowNull}>
            {nullValue || 'Select an option'}
          </option>
          {options.map((option) => (
            labelField ? (
              <option key={option[valueField]} value={option[valueField]}>
                {option[labelField]}
              </option>
            ) : (
              <option key={option} value={option}>
                {option}
              </option>
            )
          ))}
        </Form.Control>
        {isInvalid && (
          <Form.Control.Feedback type="invalid">
            {invalidText || 'Invalid'}
          </Form.Control.Feedback>
        )}
      </InputGroup>
      <Form.Label className={labelClass}>{label}</Form.Label>
    </div>
  );
};

FloatSelect.defaultProps = {
  label: 'Label',
  options: [],
  value: '',
  labelField: null,
  valueField: 'id',
  invalidText: 'Invalid option',
  onBlur: null,
  allowNull: false,
  nullValue: null,
  isInvalid: false,
  disabled: false,
  required: false,
};

FloatSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(Object),
      PropTypes.string,
    ]),
  ),
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  allowNull: PropTypes.bool,
  nullValue: PropTypes.string,
  invalidText: PropTypes.string,
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default FloatSelect;
