import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Button, ButtonGroup, Tab, Tabs } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import Save from 'components/Save';

import { AuthContext } from 'api/context';
import ValidatedForm, { FloatInput, FloatSelect } from 'components/Form/Inputs';
import StatesCities from 'US-state-cities.json';
import { stateOptions, countryOptions } from 'options';
import { i } from 'mathjs';
import TieredCost from './TieredCost';

const InstallationPackage = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const packagesEndpoint = newEndpoint('hardware/packages/');

  const panelsEndpoint = newEndpoint('hardware/panels/');
  const invertersEndpoint = newEndpoint('hardware/inverters/');
  const monitoringEndpoint = newEndpoint('hardware/monitoring/');
  const mountingEndpoint = newEndpoint('hardware/mounting/');

  // all design packages
  const [newPackage, setNewPackage] = useState();
  const [companyPackages, setCompanyPackages] = useState([]);

  const [panels, setPanels] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [monitorings, setMonitoring] = useState([]);
  const [mountings, setMounting] = useState([]);

  // const hasMicro = companyPackage.panel && companyPackage.panel.has_micro;

  // const states = Object.keys(StatesCities).map((el) => ({ name: el }));

  useEffect(() => {
    packagesEndpoint.list().then((data) => {
      if (data.length !== 0) {
        setCompanyPackages(data);
      }
    });

    panelsEndpoint.list().then((data) => {
      const sorted = data.sort((a, b) => a.model < b.model);
      setPanels(sorted.filter((el, i) => el?.is_hidden === false));
    });

    invertersEndpoint.list().then((data) => {
      const sorted = data.sort((a, b) => a.model < b.model);
      setInverters(sorted.filter((el, i) => el?.is_hidden === false));
    });

    monitoringEndpoint.list().then((data) => {
      const sorted = data.sort((a, b) => a.model < b.model);
      setMonitoring(sorted.filter((el, i) => el?.is_hidden === false));
    });

    mountingEndpoint.list().then((data) => {
      const sorted = data.sort((a, b) => a.model < b.model);
      setMounting(sorted.filter((el, i) => el?.is_hidden === false));
    });
  }, []);

  const handleUpdatePackage = (value, field, index) => {
    const updated = Array.from(companyPackages);
    updated[index][field] = value;
    setCompanyPackages(updated);
    if (field === 'tiered_costs') {
      postPackageHandler(updated[index]);
    }
  };

  // change handlers for hardware components
  const selectedPanelHandler = (value, index) => {
    const packageToUpdate = companyPackages[index];
    const panel = panels.find(({ id }) => id === value);
    const inverter = panel.has_micro ? { id: '' } : packageToUpdate.inverter;
    handleUpdatePackage(panel, 'panel', index);
    handleUpdatePackage(inverter, 'inverter', index);
  };

  const selectedInverterHandler = (value, index) => {
    const inverter = inverters.find(({ id }) => id === value);
    handleUpdatePackage(inverter, 'inverter', index);
  };

  const selectedMonitoringHandler = (value, index) => {
    const monitoring = monitorings.find(({ id }) => id === value);
    handleUpdatePackage(monitoring, 'monitoring', index);
  };

  const selectedMountingHandler = (value, index) => {
    const mounting = mountings.find(({ id }) => id === value);
    handleUpdatePackage(mounting, 'mounting', index);
  };

  const postPackageHandler = (packageToUpdate) => {
    const { id, ...totalPackage } = packageToUpdate;

    totalPackage.panel = totalPackage.panel.id;
    totalPackage.inverter = packageToUpdate.panel && packageToUpdate.panel.has_micro ? null : totalPackage.inverter.id;
    totalPackage.monitoring = totalPackage.monitoring ? totalPackage.monitoring.id : null;
    totalPackage.mounting = totalPackage.mounting.id;

    if (id) {
      return packagesEndpoint.update(id, totalPackage)
        .then(() => displaySnack({ variant: 'success', message: `Updated ${packageToUpdate.name}.` }))
        .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${packageToUpdate.name}.` }));
    }
    return packagesEndpoint.create(totalPackage)
      .then(() => packagesEndpoint.list().then((data) => setCompanyPackages(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${packageToUpdate.name}.` });
        setNewPackage();
      })
      .catch(() => {
        displaySnack({
          variant: 'danger',
          message: 'Failed to add new package',
        });
      });
    // .then(() => window.location.reload());
  };

  const handleDelete = (index) => {
    const updated = Array.from(companyPackages);
    const rm = updated.splice(index, 1)[0];
    packagesEndpoint.remove(rm.id)
      .then(() => { setCompanyPackages(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.name}` }));
  };

  const initPackage = () => setNewPackage({ name: '', cost: '', tiered_costs: [], country: 'United States', state: '', panel: '', mounting: '', inverter: '', monitoring: '' });

  const handleNewPackageUpdate = (value, field) => {
    if (field === 'panel') {
      const panel = panels.find(({ id }) => id === value);
      const inverter = panel.has_micro ? { id: '' } : newPackage.inverter;
      setNewPackage({ ...newPackage, panel, inverter });
    } else if (field === 'inverter') {
      const inverter = inverters.find(({ id }) => id === value);
      setNewPackage({ ...newPackage, inverter });
    } else if (field === 'mounting') {
      const mounting = mountings.find(({ id }) => id === value);
      setNewPackage({ ...newPackage, mounting });
    } else if (field === 'monitoring') {
      const monitoring = monitorings.find(({ id }) => id === value);
      setNewPackage({ ...newPackage, monitoring });
    }
  };

  const nostateItem = companyPackages.find((s) => s.state == null) ?? { name: 'default', state: null };
  let nostateIndex = companyPackages.indexOf(nostateItem);
  if (nostateIndex < 0) {
    companyPackages.push(nostateItem);
    nostateIndex = companyPackages.length - 1;
  }

  return (
    <>
      <Tabs defaultActiveKey="all-states" id="uncontrolled-tab-example" className="mb-3 p-2 nav-pills nav-fill">
        <Tab eventKey="all-states" title="All States/Provinces">
          <ValidatedForm onSave={() => postPackageHandler(nostateItem)}>
            <Row>
              <Col sm={3} xs={12}>
                <FloatInput
                  required
                  label="Name"
                  // value={nostateItem.name || ''}
                  value="default"
                  onChange={(e) => handleUpdatePackage(e.target.value, 'name', nostateIndex)}
                  disabled
                />
              </Col>
              <Col sm={3} xs={12} className="pr-3">
                <FloatInput
                  required
                  label="Cost (per Watt)"
                  prepend="$"
                  value={nostateItem.cost || ''}
                  onChange={(e) => handleUpdatePackage(e.target.value, 'cost', nostateIndex)}
                />
              </Col>
              <Col sm={6} xs={12}>
                <TieredCost costs={nostateItem?.tiered_costs} handleUpdatePackage={handleUpdatePackage} index={nostateIndex} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={6} xs={12}>
                <FloatSelect
                  required
                  label="Panel"
                  nullValue="Choose Panel"
                  options={panels}
                  labelField="model"
                  value={nostateItem.panel ? nostateItem.panel.id : ''}
                  onChange={(e) => selectedPanelHandler(e.target.value, nostateIndex)}
                />
              </Col>
              <Col sm={6} xs={12}>
                <FloatSelect
                  required
                  label="Mounting Hardware"
                  nullValue="Choose Mounting Hardware"
                  options={mountings}
                  labelField="model"
                  value={nostateItem.mounting ? nostateItem.mounting.id : ''}
                  onChange={(e) => selectedMountingHandler(e.target.value, nostateIndex)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={6} xs={12}>
                <FloatSelect
                  required
                  label="Inverter"
                  nullValue={nostateItem.panel && nostateItem.panel.has_micro ? 'Built-in' : 'Choose Inverter'}
                  options={inverters}
                  labelField="model"
                  value={nostateItem.inverter ? nostateItem.inverter.id : ''}
                  onChange={(e) => selectedInverterHandler(e.target.value, nostateIndex)}
                  disabled={nostateItem.panel && nostateItem.panel.has_micro}
                />
              </Col>
              <Col sm={6} xs={12}>
                <FloatSelect
                  required
                  label="Monitoring System"
                  nullValue="Choose Monitoring System"
                  options={monitorings}
                  labelField="model"
                  value={nostateItem.monitoring ? nostateItem.monitoring.id : ''}
                  onChange={(e) => selectedMonitoringHandler(e.target.value, nostateIndex)}
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-3 float-left">
              <Col>
                <Button type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </ValidatedForm>
        </Tab>
        <Tab eventKey="per-state" title="Per State/Province">
          {companyPackages.map((item, index) => (
            (item.state !== null && (
              <Row>
                <Col>
                  <ValidatedForm onSave={() => postPackageHandler(item)}>
                    <Row>
                      <Col sm={3} xs={12} className='pr-0'>
                        <FloatInput
                          required
                          label="Name"
                          value={item.name || ''}
                          onChange={(e) => handleUpdatePackage(e.target.value, 'name', index)}
                        />
                      </Col>
                      <Col sm={2} xs={12} className="pr-0">
                        <FloatInput
                          required
                          label="Cost (per Watt)"
                          prepend="$"
                          value={item.cost || ''}
                          onChange={(e) => handleUpdatePackage(e.target.value, 'cost', index)}
                        />
                      </Col>
                      <Col sm={2} xs={12}>
                        <FloatSelect
                          required
                          label="State"
                          nullValue="Choose State"
                          options={stateOptions['United States'].includes(item?.state) ? stateOptions['United States'].map((el) => ({name: el})) : stateOptions['Canada'].map((el) => ({name: el}))}
                          labelField="name"
                          value={item.state || ''}
                          onChange={(e) => handleUpdatePackage(e.target.value, 'state', index)}
                        />
                      </Col>
                      <Col sm={5} xs={12}>
                        <TieredCost costs={item?.tiered_costs} handleUpdatePackage={handleUpdatePackage} index={index} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={6} xs={12}>
                        <FloatSelect
                          required
                          label="Panel"
                          nullValue="Choose Panel"
                          options={panels}
                          labelField="model"
                          value={item.panel ? item.panel.id : ''}
                          onChange={(e) => selectedPanelHandler(e.target.value, index)}
                        />
                      </Col>
                      <Col sm={6} xs={12}>
                        <FloatSelect
                          required
                          label="Mounting Hardware"
                          nullValue="Choose Mounting Hardware"
                          options={mountings}
                          labelField="model"
                          value={item.mounting ? item.mounting.id : ''}
                          onChange={(e) => selectedMountingHandler(e.target.value, index)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={6} xs={12}>
                        <FloatSelect
                          required
                          label="Inverter"
                          nullValue={item.panel && item.panel.has_micro ? 'Built-in' : 'Choose Inverter'}
                          options={inverters}
                          labelField="model"
                          value={item.inverter ? item.inverter.id : ''}
                          onChange={(e) => selectedInverterHandler(e.target.value, index)}
                          disabled={item.panel && item.panel.has_micro}
                        />
                      </Col>
                      <Col sm={6} xs={12}>
                        <FloatSelect
                          required
                          label="Monitoring System"
                          nullValue="Choose Monitoring System"
                          options={monitorings}
                          labelField="model"
                          value={item.monitoring ? item.monitoring.id : ''}
                          onChange={(e) => selectedMonitoringHandler(e.target.value, index)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3 float-right">
                      <Col>
                        <ButtonGroup size="sm">
                          <Button variant="outline-primary p-2" type="submit">
                            <Save />
                          </Button>
                          <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                            <Trash />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </ValidatedForm>
                </Col>
              </Row>
            ))
          ))}
          <br />
          {newPackage === undefined ? (
            <Row className="mt-5">
              <Col>
                <Button onClick={initPackage}>
                  + Package
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <ValidatedForm onSave={() => postPackageHandler(newPackage)}>
                <Row>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Name"
                      value={newPackage.name || ''}
                      onChange={(e) => setNewPackage({ ...newPackage, name: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12} className="pr-0">
                    <FloatInput
                      required
                      label="Cost (per Watt)"
                      prepend="$"
                      value={newPackage.cost || ''}
                      onChange={(e) => setNewPackage({ ...newPackage, cost: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      required
                      label="Country"
                      nullValue="Choose Country"
                      options={countryOptions.map((el) => ({name: el}))}
                      labelField="name"
                      value={newPackage.country || ''}
                      onChange={(e) => setNewPackage({ ...newPackage, country: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      required
                      label="State / Province"
                      nullValue="Choose State / Province"
                      options={stateOptions[newPackage?.country].map((el) => ({name: el}))}
                      labelField="name"
                      value={newPackage.state || ''}
                      onChange={(e) => setNewPackage({ ...newPackage, state: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={6} xs={12}>
                    <FloatSelect
                      required
                      label="Panel"
                      nullValue="Choose Panel"
                      options={panels}
                      labelField="model"
                      value={newPackage.panel ? newPackage.panel.id : ''}
                      onChange={(e) => handleNewPackageUpdate(e.target.value, 'panel')}
                    />
                  </Col>
                  <Col sm={6} xs={12}>
                    <FloatSelect
                      required
                      label="Mounting Hardware"
                      nullValue="Choose Mounting Hardware"
                      options={mountings}
                      labelField="model"
                      value={newPackage.mounting ? newPackage.mounting.id : ''}
                      onChange={(e) => handleNewPackageUpdate(e.target.value, 'mounting')}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={6} xs={12}>
                    <FloatSelect
                      required
                      label="Inverter"
                      nullValue={newPackage.panel && newPackage.panel.has_micro ? 'Built-in' : 'Choose Inverter'}
                      options={inverters}
                      labelField="model"
                      value={newPackage.inverter ? newPackage.inverter.id : ''}
                      onChange={(e) => handleNewPackageUpdate(e.target.value, 'inverter')}
                      disabled={newPackage.panel && newPackage.panel.has_micro}
                    />
                  </Col>
                  <Col sm={6} xs={12}>
                    <FloatSelect
                      required
                      label="Monitoring System"
                      nullValue="Choose Monitoring System"
                      options={monitorings}
                      labelField="model"
                      value={newPackage.monitoring ? newPackage.monitoring.id : ''}
                      onChange={(e) => handleNewPackageUpdate(e.target.value, 'monitoring')}
                    />
                  </Col>
                </Row>
                <Row className="mt-3 mb-3 float-right">
                  <Col>
                    <ButtonGroup size="sm">
                      <Button variant="outline-primary p-2" type="submit">
                        Save
                      </Button>
                      <Button variant="outline-primary p-2" onClick={() => setNewPackage()}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ValidatedForm>
            </>
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default InstallationPackage;
